import axios from "axios";
import {API_URL} from "../constants";
import {authHeader} from "../Utilities";
import {TagReference} from "./ApiModels/TagReference";
import {TagObject} from "./ApiModels/TagObject";
import {ResultReference} from "./ApiModels/ResultReference";
import {NodeModel, NodeReferenceModel} from "./HierarchiesService";

class AllResultsService {
    getAnnotationObject(tagReference: TagReference) {
        return axios.post<TagObject | undefined>(API_URL + "/all_results/get_annotation_object", {
            tag_reference: tagReference,
        }, {headers: authHeader()});
    }

    getTagReferenceByNodeReference(
        node: {text: string, label: string},
        nodeReference: NodeReferenceModel
    ) {
        return axios.post<TagReference | undefined>(API_URL + "/all_results/tag_reference_by_raw_node_object_reference", {
            ref: nodeReference,
            node: node
        }, {headers: authHeader()});
    }

    getNodeByTagReference(hierarchyId: string, tagReference: TagReference) {
        return axios.post<NodeModel>(API_URL + "/all_results/hierarchy_node_by_tag_reference", {
            tag_reference: tagReference,
        }, {params: {hierarchy_id: hierarchyId}, headers: authHeader()});
    }

    getHierarchyNodeAttributes(hierarchyId: string, resultReference: ResultReference) {
        return axios.post(API_URL + "/all_results/hierarchy_node_attributes", {
            result_reference: resultReference,
        }, {params: {hierarchy_id: hierarchyId}, headers: authHeader()});
    }

    getInfo(resultReference: ResultReference) {
        return axios.post(API_URL + "/all_results/info", {
            result_reference: resultReference,
        }, {headers: authHeader()});
    }
}

export default new AllResultsService();
