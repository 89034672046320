import React, {useCallback, useEffect, useState} from "react";
import {Spin, Table} from "antd";

export class AsyncDataSource {
    /**
     *
     * @param {number} page
     * @param {number} limit
     * @returns {Promise<any[]>}
     */
    async values(page, limit) {
        return [];
    }
}


export class InMemoryAsyncDataSource extends AsyncDataSource {
    constructor(data) {
        super();
        if (typeof data !== "function") {
            this._data = async () => data;
        } else {
            this._data = data;
        }
    }

    /**
     *
     * @param {number} page
     * @param {number} limit
     * @returns {Promise<any[]>}
     */
    async values(page, limit) {
        const data = await this._data();
        return data.slice((page - 1) * limit, page * limit + 1);
    }
}


export function ManualPagedTable({
                                     asyncDataSource,
                                     defaultLimit = 10,
                                     spinDelay = 100,
                                     showSizeChanger = true,
                                     ...rest
                                 }) {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(defaultLimit);

    useEffect(() => {
        setPage(1);
    }, [limit, asyncDataSource]);

    const onPageChange = useCallback((page, pageSize) => {
        setPage(page);
        setLimit(pageSize);
    }, []);

    return (
        <TablePage
            asyncDataSource={asyncDataSource}
            page={page}
            limit={limit}
            onPageChange={onPageChange}
            spinDelay={spinDelay}
            showSizeChanger={showSizeChanger}
            {...rest}
        />
    );
}


export function TablePage({
                              asyncDataSource,
                              page,
                              limit,
                              onPageChange,
                              showSizeChanger = true,
                              spinDelay = 100,
                              ...rest
                          }) {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [spinning, setSpinning] = useState(false);

    useEffect(() => {
        setMaxPage(0);
    }, [asyncDataSource]);

    useEffect(() => {
        setMaxPage(0);
    }, [limit]);

    useEffect(() => {
        let cancelled = false;
        setSpinning(true);

        asyncDataSource.values(page, limit)
            .then(data => {
                if (!cancelled) {
                    setData(data);

                    setMaxPage(prev => {
                        const newMax = Math.max(prev, page);

                        if (page === newMax) {
                            setTotal((newMax - 1) * limit + data.length);
                        }

                        return newMax;
                    });
                }
            })
            .finally(_ => {
                if (!cancelled) {
                    setSpinning(false);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [asyncDataSource, page, limit]);

    return (
        <Spin spinning={spinning} delay={spinDelay}>
            <Table
                {...rest}
                dataSource={data.slice(0, limit)}
                pagination={{
                    position: "bottomCenter",
                    showSizeChanger: showSizeChanger,
                    current: page,
                    total: total,
                    pageSize: limit,
                    onChange: onPageChange
                }}
            />
        </Spin>
    );
}
