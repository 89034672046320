import React, {useEffect, useState} from "react";
import {Button, Card, Col, Divider, message, Row, Space, Table, Tag, Upload} from "antd";
import axios from "axios";
import {API_URL} from "../../constants";
import {authHeader, download} from "../../Utilities";
import {DataSourceFilterApplier, DataSourceFiltersEditor, ExactMatchItemFilter} from "../Misc/DataSourceFilter";


function loadSystemAttributes(workspaceId) {
    return axios.get(
            API_URL + `/workspaces/${workspaceId}/system_attributes`,
            {headers: authHeader()}
        );
}

export function ConfigureSystemAttributes({workspaceId}) {
    function onDownload() {
        axios.get(`${API_URL}/workspaces/${workspaceId}/system_attributes/config`,
            {
                headers: authHeader(),
                responseType: "blob"
            }).then((response) => {
            const blob = new window.Blob([response.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            download(blob, "system_attributes.xlsx");
        });
    }

    return (
      <ConfigureSystemAttributesBase
          workspaceId={workspaceId}
          loadSystemAttributes={loadSystemAttributes}
          uploadAction={`${API_URL}/workspaces/${workspaceId}/system_attributes/config`}
          onDownload={onDownload}
          mainTitle={"Configure system attributes"}
      />
    );
}

export function ConfigureSystemAttributesBase({workspaceId, loadSystemAttributes, uploadAction, onDownload, mainTitle}) {
    const [currentSystemAttributesData, setCurrentSystemAttributesData] = useState([]);
    const [tableFilters, setTableFilters] = useState([]);

    useEffect(() => {
        loadSystemAttributes(workspaceId).then(response => {
            setCurrentSystemAttributesData(response.data);
        });
    }, [workspaceId]);

    const uploadProps = {
        accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        name: "file",
        action: uploadAction,
        headers: authHeader(),
        onChange(info) {
            if (info.file.status === "done") {
                message.success("System Attributes loaded successfully");

                loadSystemAttributes(workspaceId).then(response => {
                    setCurrentSystemAttributesData(response.data);
                });
            } else if (info.file.status === "error") {
                const response = info.file.response;
                const responseMessage = response ? response.message : "";
                let errorMessage = "Error loading attributes";

                if (responseMessage) {
                    errorMessage += `: ${responseMessage}`;
                }

                message.error(errorMessage);
            }
        },
    };

    const columns = [
        {
            title: "Class",
            dataIndex: "object_label",
        },
        {
            title: "Attribute",
            dataIndex: "attribute_name",
        },
        {
            title: "Values",
            dataIndex: "values_list",
            render: (text) => {
                if (!text) return "";

                return (text.map(el => (
                    <Tag color={"green"} key={el}>{el}</Tag>
                )));
            }
        }
    ];

    const filterSchemas = [
        {
            attribute: "Class",
            allowedValues: currentSystemAttributesData.map((obj) => obj.object_label),
            constructor(value) {
                return new ExactMatchItemFilter(value, (e) => e.object_label, "Class");
            }
        },
        {
            attribute: "Attribute",
            allowedValues: currentSystemAttributesData.map((obj) => obj.attribute_name),
            constructor(value) {
                return new ExactMatchItemFilter(value, (e) => e.attribute_name, "Attribute");
            }
        }
    ];

    return (
        <>
            <Card title={mainTitle}>
                <Row>
                    <Col span={24}>
                        <Row style={{ marginBottom: 8 }}>
                            <Space>
                                <Upload {...uploadProps} showUploadList={false} id="upload-configuration-input">
                                    <Button type={"primary"}>Upload configuration</Button>
                                </Upload>
                                <Button id="download-configuration-button" type={"primary"} onClick={onDownload}>
                                    Download configuration
                                </Button>
                            </Space>
                            <Divider/>
                        </Row>
                            <DataSourceFiltersEditor
                                itemFilters={tableFilters}
                                onItemFiltersChanged={(filters) => {
                                    setTableFilters([...filters]);
                                }}
                                filterSchemas={filterSchemas}
                                contentRenderer={(tagsList, editor) => (
                                    <Row justify={"space-between"} style={{ marginBottom: 8 }}>
                                        <Col span={15}>
                                            {tagsList}
                                        </Col>
                                        <Col>
                                            {editor}
                                        </Col>
                                    </Row>
                                )}
                            />
                        <Row style={{ marginBottom: 8 }}>
                            <Col span={24}>
                                <DataSourceFilterApplier
                                    itemFilters={tableFilters}
                                    dataSource={currentSystemAttributesData}
                                    dataSourceRenderer={
                                        (filteredDataSource) => <Table
                                            id={"system-attributes-table-view"}
                                            dataSource={filteredDataSource}
                                            columns={columns}
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </>
    );
}