import ImageViewerObject from "./components/ImageViewerHandlers/ImageViewerObject";
import {ImageViewerCanvasObject} from "./components/ImageViewerHandlers/ImageViewerCanvasObject";
import {ImageViewerObjectFromCanvas} from "./Utilities";
import axios, {isAxiosError} from "axios";
import {UploadRequestOption} from "rc-upload/lib/interface";

export type StringWithAutocomplete<T> = T | (string & {});

export type Factory<T> = () => T;

export function memoized<T>(
    factory: Factory<T>,
    dirty: (current: T) => boolean = () => false
) {
    let result: undefined | T;
    return () => {
        if (result == null || dirty(result)) {
            result = factory();
        }
        return result;
    };
}

export function fork<A, B>(
    cond: boolean,
    ifTrue: A,
    otherwise: B,
): A | B {
    return cond ? ifTrue : otherwise;
}


export function isString(obj: any): obj is string {
    return typeof obj === "string";
}

export function ivObj(obj: ImageViewerObject | ImageViewerCanvasObject): ImageViewerObject {
    if (obj instanceof ImageViewerObject) {
        return obj;
    } else {
        return new ImageViewerObjectFromCanvas(obj);
    }
}

export async function axiosUploadRequest<T>(options: UploadRequestOption<T>) {
    const { file, onSuccess, onError, onProgress, action, headers } = options;

    const formData = new FormData();
    formData.append("file", file);

    try {
        const response = await axios.post<T>(
            action,
            formData,
            {
                headers: headers,
                onUploadProgress: (event) => {
                    const total = event.total ?? 0;
                    if (total !== 0) {
                        onProgress?.({percent: (event.loaded / total) * 100});
                    } else {
                        onProgress?.({percent: 100});
                    }
                },
            }
        );

        onSuccess?.(response.data);
    } catch (error) {
        if (isAxiosError(error)) {
            onError?.(
                {
                    ...error,
                    url: error.request.url,
                    method: error.request.method
                },
                error.response?.data
            );
        } else {
            throw error;
        }
    }
}