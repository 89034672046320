import React from "react";
import {Button, Dropdown, Menu} from "antd";
import {ViewHierarchy} from "./ViewHierarchy";
import {INodeToAdd} from "../../ImageViewerHandlers/Hierarchy/NodeToAdd";

export function NodeBufferActions({viewHierarchy}: {
    viewHierarchy: ViewHierarchy
}) {
    const ancestorSwap = () => {
        viewHierarchy.updateAddMultipleNodesList(prev => {
            const ancestors: INodeToAdd[] = [];
            prev.forEach(x => {
                x.ancestorSwap(ancestors, viewHierarchy);
            });
            return ancestors;
        });
    };

    const actionsMenu = (
        <Menu>
            <Menu.Item onClick={ancestorSwap}>
                Ancestor Swap
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={actionsMenu} trigger={["click"]}>
            <Button size="small">Actions</Button>
        </Dropdown>
    );
}
