import {Button, Card, Form, Input, message, Progress, Upload, UploadProps} from "antd";
import React, {useState} from "react";
import {API_URL} from "../../constants";
import {authHeader, UpdateJobProgress} from "../../Utilities";
import {HttpRequestHeader} from "antd/es/upload/interface";

type ImportDocumentRegistryProps = {
    workspaceId?: string
}

export function ImportDocumentRegistry({workspaceId}: ImportDocumentRegistryProps) {
    const [progress, setProgress] = useState({status: "", percent: 0});
    const [spinning, setSpinning] = useState(false);
    const [fileNameColumn, setFileNameColumn] = useState("kh_file_name");
    const [pageNumberColumn, setPageNumberColumn] = useState("kh_page_number");

    const uploadProps: Partial<UploadProps> = {
        accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        name: "file",
        action: `${API_URL}/workspaces/${workspaceId}/document_registries/import`,
        headers: authHeader() as HttpRequestHeader,
        data: {
            file_name_column: fileNameColumn,
            page_number_column: pageNumberColumn
        },
        onChange(info) {
            if (info.file.status === "done") {
                const updateProgress = new UpdateJobProgress().withDefaultUpdateProgress(setProgress);
                setSpinning(true);
                updateProgress.do(
                    `${API_URL}/workspaces/${workspaceId}/document_registries/import/${info.file.response}/progress`
                ).then(_ => {
                    message.success("Document Register imported successfully");
                }).catch(error => {
                    console.error(error);
                    message.error("Failed to import");
                }).finally(() => {
                    setSpinning(false);
                });
            } else if (info.file.status === "error") {
                message.error("Failed to import registry!");
            }
        },
    };

    return (
        <Card title="Import Document Register">
            <Form.Item label="File Name Column" style={{marginBottom: "12px"}}>
                <Input
                    size="small"
                    value={fileNameColumn}
                    onChange={(e) => setFileNameColumn(e.target.value)}
                />
            </Form.Item>
            <Form.Item label="Page Number Column" style={{marginBottom: "12px"}}>
                <Input
                    size="small"
                    value={pageNumberColumn}
                    onChange={(e) => setPageNumberColumn(e.target.value)}
                />
            </Form.Item>
            <Upload {...uploadProps}>
                <Button type={"primary"}>Upload registry</Button>
            </Upload>
            {spinning && (
                <div>
                    {progress.status}
                    <Progress percent={progress.percent} />
                </div>
            )}
        </Card>
    );
}