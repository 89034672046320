import {fabric} from "fabric";
import axios, {GenericAbortSignal} from "axios";
import {API_URL, API_V2_URL} from "../../constants";
import {authHeader} from "../../Utilities";
import {Buffer} from "buffer";
import {fetchAbortable, MayBeAborted} from "../MayBeAborted";
import {changed$, DepSource, useDep} from "../useDep";
import {Subject} from "rxjs";
import { DocumentRegistryPresets } from "./DocumentRegistryPresets";
import { API_V2 } from "../..";

export type Annotation = {}

export type Preview = {
    image: fabric.Image
}

export class DocumentRegistryItem {
    private readonly registryId: string;
    private readonly _id: string;
    private readonly sets: DocumentRegistrySets;

    constructor(registryId: string, id: string, sets: DocumentRegistrySets) {
        this.registryId = registryId;
        this._id = id;
        this.sets = sets;
    }

    id(): string {
        return this._id;
    }

    async editFileName(currentFileName: string, newFileName: string): Promise<void> {
        await API_V2.put(
            `/documents_registry/${this.registryId}/items/${this.id()}/file_name`,
            {
                current_value: currentFileName,
                new_value: newFileName
            }
        );
    }

    async editPageNumber(currentPageNumber: number, newPageNumber: number): Promise<void> {
        await API_V2.put(
            `/documents_registry/${this.registryId}/items/${this.id()}/page_number`,
            {
                current_value: currentPageNumber,
                new_value: newPageNumber
            }
        );
    }

    async editField(fieldName: string, currentFieldValue: string, newFieldValue: string): Promise<void> {
        await API_V2.put(
            `/documents_registry/${this.registryId}/items/${this.id()}/fields`,
            {
                field_name: fieldName,
                current_value: currentFieldValue,
                new_value: newFieldValue
            }
        );
    }

    async addToSet(setId: string): Promise<void> {
        await this.sets.addItemToSet(this._id, setId);
    }

    async removeFromSet(setId: string): Promise<void> {
        await this.sets.removeItemFromSet(this._id, setId);
    }

    async previewCompressed(): Promise<Preview> {
        return new Promise((resolve, reject) => {
            API_V2.get(
                `/documents_registry/${this.registryId}/items/${this.id()}/image_id`
            ).then(async imageId => {
                const image = await axios.get(
                    `${API_URL}/images_compressed/${imageId.data}.png`,
                    {
                        responseType: "arraybuffer",
                        headers: authHeader(),
                    }
                );
                const b64 = Buffer.from(image.data, "binary").toString("base64");
                const imgUrlBinary = `data:application/octet-stream;base64,${b64}`;
                fabric.Image.fromURL(imgUrlBinary, function (img) {
                    resolve({
                        image: img
                    });
                });
                return imgUrlBinary;
            }).catch(reject);
        });
    }

    async delete(): Promise<void> {
        await API_V2.delete(`/documents_registry/${this.registryId}/items/${this.id()}`);
    }

    async annotation(): Promise<Annotation> {
        return {};
    }
}

type DocumentRegistryQueryItem = {
    readonly id: string,
    readonly fields: {key: string, value: string}[]
    readonly fileName: string;
    readonly pageNumber: number;
    readonly sets: string[];

    asItem(): DocumentRegistryItem;
}

type RequestFilter = {
    type: "field" | "file_name" | "id" | "set" | "scope" | "page_number",
    operation: "search" | "exact" | "not",
    field?: string,
    value: string
}

interface DocumentRegistryFilter {
    toRequestFilter(): RequestFilter;
}

type RequestSort = {
    type: "field" | "file_name" | "set" | "page_number",
    order: 1 | -1,
    field?: string,
    set_id?: string,
}

interface DocumentRegistrySort {
    toRequestSort(): RequestSort;
}

export class DocumentRegistryQuery {
    private readonly registry: DocumentRegistry;
    private readonly filters: DocumentRegistryFilter[] = [];
    private readonly sort: DocumentRegistrySort[] = [];

    constructor(registry: DocumentRegistry) {
        this.registry = registry;
    }

    withSetSort(setId: string, order: -1 | 1) {
        this.sort.push({
            toRequestSort(): RequestSort {
                return {
                    type: "set",
                    set_id: setId,
                    order
                };
            }
        });
        return this;
    }

    withFieldSort(fieldName: string, order: -1 | 1) {
        this.sort.push({
            toRequestSort(): RequestSort {
                return {
                    type: "field",
                    field: fieldName,
                    order
                };
            }
        });
        return this;
    }

    withFileNameSort(order: -1 | 1) {
        this.sort.push({
            toRequestSort(): RequestSort {
                return {
                    type: "file_name",
                    order
                };
            }
        });
        return this;
    }

    withPageNumberSort(order: -1 | 1) {
        this.sort.push({
            toRequestSort(): RequestSort {
                return {
                    type: "page_number",
                    order
                };
            }
        });
        return this;
    }

    withPartialFieldFilter(fieldName: string, fieldSearchValue: string) {
        this.filters.push({
            toRequestFilter(): RequestFilter {
                return {
                    type: "field",
                    operation: "search",
                    field: fieldName,
                    value: fieldSearchValue,
                };
            }
        });
        return this;
    }

    withSetFilter(setId: string) {
        this.filters.push({
            toRequestFilter(): RequestFilter {
                return {
                    type: "set",
                    operation: "exact",
                    value: setId,
                };
            }
        });
        return this;
    }

    withSetFilterNot(setId: string) {
        this.filters.push({
            toRequestFilter(): RequestFilter {
                return {
                    type: "set",
                    operation: "not",
                    value: setId,
                };
            }
        });
        return this;
    }

    withExactFieldFilter(fieldName: string, fieldExactValue: string) {
        this.filters.push({
            toRequestFilter(): RequestFilter {
                return {
                    type: "field",
                    operation: "exact",
                    field: fieldName,
                    value: fieldExactValue,
                };
            }
        });
        return this;
    }

    withScopeFilter(scopeId: string) {
        this.filters.push({
            toRequestFilter(): RequestFilter {
                return {
                    type: "scope",
                    operation: "exact",
                    value: scopeId,
                };
            }
        });
        return this;
    }

    withIdFilter(id: string) {
        this.filters.push({
            toRequestFilter(): RequestFilter {
                return {
                    type: "id",
                    operation: "exact",
                    value: id,
                };
            }
        });
        return this;
    }

    withPartialFileNameFilter(searchValue: string) {
        this.filters.push({
            toRequestFilter(): RequestFilter {
                return {
                    type: "file_name",
                    operation: "search",
                    value: searchValue,
                };
            }
        });
        return this;
    }

    withExactPageNumberFilter(searchValue: string) {
        this.filters.push({
            toRequestFilter(): RequestFilter {
                return {
                    type: "page_number",
                    operation: "exact",
                    value: searchValue,
                };
            }
        });
        return this;
    }

    async get(skip: number, limit: number): Promise<readonly DocumentRegistryQueryItem[]> {
        const res = await API_V2.post(
            `/documents_registry/${this.registry.id()}/items/query`,
            {
                filters: this.filters.map(x => x.toRequestFilter()),
                sort: this.sort.map(x => x.toRequestSort()),
            },
            {params: {skip, limit}}
        );
        const registry = this.registry;
        return res.data.map((x: any) => new class implements DocumentRegistryQueryItem {
            readonly fields: { key: string; value: string }[] = x.fields;
            readonly fileName: string = x.file_name;
            readonly pageNumber: number = x.page_number;
            readonly id: string = x.id;
            readonly sets: string[] = x.sets;
            asItem(): DocumentRegistryItem {
                return registry.getById(this.id);
            }
        }());
    }
}

interface DirModel {
    readonly name: string;
}

interface FileModel {
    readonly name: string,
    readonly item: DocumentRegistryItem,
}

export interface FileSystemResult {
    readonly dirs: readonly DirModel[];
    readonly files: readonly FileModel[];
}

interface PivotTableItem {
    readonly value: string;
}

type RemoteDocumentRegistryScopeAboutModel = {
    id: string;
    name: string;
    filters: any;
}

class RemoteDocumentRegistryScope {
    private readonly registryId: string;
    private readonly id: string;

    constructor(registryId: string, id: string) {
        this.id = id;
        this.registryId = registryId;
    }

    async delete(): Promise<void> {
        await API_V2.delete(`/documents_registry/${this.registryId}/scopes/${this.id}`);
    }

    async edit(name: string, filters: any) {
        const model = await API_V2.put(
            `/documents_registry/${this.registryId}/scopes/${this.id}`,
            {name, filters}
        );
        return model.data;
    }
}

class RemoteDocumentRegistryScopes {
    private readonly registryId: string;

    constructor(registryId: string) {
        this.registryId = registryId;
    }

    async scopeFilterSchema(): Promise<any> {
        const schema = await API_V2.get("/documents_registry/scope_filter_schema");
        return schema.data;
    }

    async create(name: string, filters: any): Promise<RemoteDocumentRegistryScopeAboutModel> {
        const scope = await API_V2.post(
            `/documents_registry/${this.registryId}/scopes`,
            {name, filters}
        );
        return scope.data;
    }

    getById(id: string): RemoteDocumentRegistryScope {
        return new RemoteDocumentRegistryScope(this.registryId, id);
    }

    async getAllAbout(): Promise<readonly RemoteDocumentRegistryScopeAboutModel[]> {
        const scopes = await API_V2.get(`/documents_registry/${this.registryId}/scopes`);
        return scopes.data;
    }
}

export type DocumentRegistryScope = RemoteDocumentRegistryScopeAboutModel;

export class DocumentRegistryScopes implements DepSource {
    public readonly [changed$]: Subject<void> = new Subject<void>();

    private readonly remoteScopes: RemoteDocumentRegistryScopes;
    private list: DocumentRegistryScope[] = [];
    private _scopeFilterSchema: any | undefined = undefined;

    constructor(registryId: string) {
        this.remoteScopes = new RemoteDocumentRegistryScopes(registryId);
    }

    scopeFilterSchema(): any | undefined {
        return this._scopeFilterSchema;
    }

    async reload(): Promise<void> {
        await Promise.all([
            this.remoteScopes.getAllAbout().then(scopes => {
                this.setList([...scopes]);
            }),
            this.remoteScopes.scopeFilterSchema().then(schema => {
                this._scopeFilterSchema = schema;
                this[changed$].next();
            })
        ]);
    }

    async edit(id: string, name: string, filters: any) {
        const scope = await this.remoteScopes.getById(id).edit(name, filters);
        this.setList(this.list.map(x => x.id === id ? scope : x));
    }

    async create(name: string, filters: any): Promise<DocumentRegistryScope> {
        const scope = await this.remoteScopes.create(name, filters);
        this.setList([...this.list, scope]);
        return scope;
    }

    async delete(id: string): Promise<void> {
        await this.remoteScopes.getById(id).delete();
        this.setList(this.list.filter(x => x.id !== id));
    }

    asList(): readonly DocumentRegistryScope[] {
        return this.list;
    }

    private setList(scopes: DocumentRegistryScope[]) {
        this.list = scopes;
        this[changed$].next();
    }
}

export class DocumentRegistrySet {
    private readonly _id: string;
    private readonly _name: string;

    constructor(id: string, name: string) {
        this._id = id;
        this._name = name;
    }

    id(): string {
        return this._id;
    }

    name(): string {
        return this._name;
    }
}

export class DocumentRegistrySets implements DepSource {
    public readonly setAdded$: Subject<DocumentRegistrySet> = new Subject<DocumentRegistrySet>();
    public readonly [changed$]: Subject<void> = new Subject<void>();

    private readonly registryId: string;
    private list: DocumentRegistrySet[] = [];

    constructor(registry: string) {
        this.registryId = registry;
    }

    async reload(): Promise<void> {
        const sets = await API_V2.get(`/documents_registry/${this.registryId}/sets`);
        this.setList(sets.data.map((x: any) => new DocumentRegistrySet(x.id, x.name)));
    }

    async add(name: string): Promise<void> {
        const setData = await API_V2.post(
            `/documents_registry/${this.registryId}/sets`,
            {name}
        );
        const set = new DocumentRegistrySet(setData.data.id, setData.data.name);
        this.setList([...this.list, set]);
        this.setAdded$.next(set);
    }

    async delete(id: string): Promise<void> {
        await API_V2.delete(`/documents_registry/${this.registryId}/sets/${id}`);
        this.setList(this.list.filter(x => x.id() !== id));
    }

    async addItemToSet(itemId: string, setId: string): Promise<void> {
        await API_V2.post(
            `/documents_registry/${this.registryId}/sets/${setId}/items`,
            {item_id: itemId}
        );
    }

    async removeItemFromSet(itemId: string, setId: string): Promise<void> {
        await API_V2.delete(`/documents_registry/${this.registryId}/sets/${setId}/items/${itemId}`);
    }

    asList(): readonly DocumentRegistrySet[] {
        return [...this.list];
    }

    getSetNameById(setId: string): string{
        return this.list.filter(x => x.id() === setId)?.[0]?.name();
    }

    private setList(columns: DocumentRegistrySet[]) {
        this.list = columns;
        this[changed$].next();
    }
}

export type ReadonlyDocumentRegistryColumns = Pick<
    DocumentRegistryColumns, "reload" | "asList" | "includes"
> & DepSource;

export class DocumentRegistryColumns implements DepSource {
    public readonly columnAdded$: Subject<string> = new Subject<string>();
    public readonly [changed$]: Subject<void> = new Subject<void>();

    private readonly registryId: string;
    private list: string[] = [];

    constructor(registry: string) {
        this.registryId = registry;
    }

    async reload(): Promise<void> {
        const columns = await API_V2.get(`/documents_registry/${this.registryId}/columns`);
        this.setList(columns.data);
    }

    async add(name: string): Promise<void> {
        const columns = await API_V2.post(
            `/documents_registry/${this.registryId}/columns`,
            {name}
        );
        this.setList(columns.data);
        this.columnAdded$.next(name);
    }

    async delete(name: string): Promise<void> {
        const columns = await API_V2.delete(`/documents_registry/${this.registryId}/columns/${name}`);
        this.setList(columns.data);
    }

    includes(column: string) {
        return this.list.includes(column);
    }

    asList(): readonly string[] {
        return [...this.list];
    }

    private setList(columns: string[]) {
        this.list = columns;
        this[changed$].next();
    }
}

export function useRegistryScopes(registry: DocumentRegistry) {
    return useDep(
        () => registry.scopes(),
        [registry]
    );
}

export function useRegistryColumns(registry: DocumentRegistry) {
    return useDep(
        () => registry.columns(),
        [registry]
    );
}

export function useRegistrySets(registry: DocumentRegistry) {
    return useDep(
        () => registry.sets(),
        [registry]
    );
}
export function useRegistryPresets(registry: DocumentRegistry) {
    return useDep(
        () => registry.presets(),
        [registry]
    );
}

export class DocumentRegistry {
    public readonly itemAdded$: Subject<DocumentRegistryItem> = new Subject<DocumentRegistryItem>();

    private readonly _id: string;
    private readonly _columns: DocumentRegistryColumns;
    private readonly _sets: DocumentRegistrySets;
    private readonly _scopes: DocumentRegistryScopes;
    private readonly _presets: DocumentRegistryPresets;

    constructor(id: string) {
        this._id = id;
        this._columns = new DocumentRegistryColumns(id);
        this._sets = new DocumentRegistrySets(id);
        this._scopes = new DocumentRegistryScopes(id);
        this._presets = new DocumentRegistryPresets(id);
    }

    id() {
        return this._id;
    }

    query(): DocumentRegistryQuery {
        return new DocumentRegistryQuery(this);
    }

    async fileSystem(rootDir: string): Promise<FileSystemResult> {
        const data = (await API_V2.get(
            `/documents_registry/${this.id()}/file_system`,
            {params: {root_dir: rootDir}},
        )).data;
        const getById = (id: string) => this.getById(id);
        return new class implements FileSystemResult {
            dirs = data.dirs.map((dir: any) => (new class implements DirModel {
                name = dir.name;
            }()));

            files = data.files.map((file: any) => (new class implements FileModel {
                name = file.file_name;
                item = getById(file.id);
            }()));
        }();
    }

    async pivotTable(
        columns: string[], path: string[], limit?: number, signal?: GenericAbortSignal
    ): Promise<MayBeAborted<PivotTableItem[]>> {
        return fetchAbortable(async () => {
            const data = (await API_V2.post(
                `/documents_registry/${this.id()}/pivot_table`,
                {columns, path},
                {params: {limit}, signal},
            )).data;
            return data.map((x: any) => new class implements PivotTableItem {
                value = x.value;
            }()) as PivotTableItem[];
        });
    }

    columns(): DocumentRegistryColumns {
        return this._columns;
    }

    sets(): DocumentRegistrySets {
        return this._sets;
    }

    scopes(): DocumentRegistryScopes {
        return this._scopes;
    }

    presets(): DocumentRegistryPresets {
        return this._presets;
    }

    async addItem(fileName: string, pageNumber: number): Promise<DocumentRegistryItem> {
        const response = await API_V2.post(
            `/documents_registry/${this.id()}/items`,
            {file_name: fileName, page_number: pageNumber}
        );
        const item = this.getById(response.data.id);
        this.itemAdded$.next(item);
        return item;
    }

    getById(id: string): DocumentRegistryItem {
        return new DocumentRegistryItem(this.id(), id, this.sets());
    }
}

export class DocumentRegistries {
    main(): DocumentRegistry {
        return new DocumentRegistry("main");
    }
}
