import React, {useCallback, useState} from "react";
import {CustomObjectAttribute, CustomObjectAttributes, CustomObjectAttributesSource} from "./CustomAttributesSource";
import {useFetchWithLoading} from "../../../../hooks/useFetch";
import {message} from "antd";
import {ImageViewerCanvasObject} from "../../../ImageViewerHandlers/ImageViewerCanvasObject";
import axios from "axios";

type DeleteAttribute = (key: string) => void;

type UpdateAttribute = (key: string, value: string) => void;

type CreateAttribute = (key: string, value: string) => void;

type CustomAttributesContextType = {
    usesCustomAttributes: boolean,
    isLoading: boolean,
    attributes: CustomObjectAttribute[],
    deleteAttribute: DeleteAttribute,
    updateAttribute: UpdateAttribute,
    createAttribute: CreateAttribute,
    notFound: boolean,
};

export const CustomAttributesContext = React.createContext<CustomAttributesContextType>(
    {} as CustomAttributesContextType
);

type Props = {
    selectedObject?: ImageViewerCanvasObject,
    children: React.ReactNode,
    source?: CustomObjectAttributesSource
};

function withSuccessMessage(origin: Function, msg: string) {
    return function () {
        // @ts-ignore
        origin(...arguments);
        message.success(msg);
    };
}

export function CustomAttributesContextProvider(
    {children, source, selectedObject}: Props
) {
    const [attributes, setAttributes] = useState<CustomObjectAttributes | null>(null);

    const isLoading = useFetchWithLoading(
        async () => {
            if (selectedObject == null) return null;
            return await source?.load(selectedObject) ?? null;
        },
        setAttributes,
        (e) => {
            setAttributes(null);
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 404) {
                    return;
                }
            }
            message.error("Failed to load!");
        },
        [source, selectedObject]
    );

    const deleteAttribute = useCallback<DeleteAttribute>((key: string) => {
        attributes!.deleteAttribute(key).then(
            withSuccessMessage(setAttributes, "Attribute deleted")
        ).catch((error) => {
            message.error("Failed to delete!");
        });
    }, [attributes]);

    const updateAttribute = useCallback<UpdateAttribute>((key: string, value: string) => {
        attributes!.updateAttributeValue(key, value).then(
            withSuccessMessage(setAttributes, "Attribute updated")
        ).catch((error) => {
            message.error("Failed to update!");
        });
    }, [attributes]);

    const createAttribute = useCallback<CreateAttribute>((key: string, value: string) => {
        attributes!.createAttribute(key, value).then(
            withSuccessMessage(setAttributes, "Attribute updated")
        ).catch((error) => {
            message.error("Failed to create!");
        });
    }, [attributes]);

    return (
        <CustomAttributesContext.Provider
            children={children}
            value={{
                createAttribute,
                updateAttribute,
                deleteAttribute,
                notFound: attributes == null,
                attributes: attributes?.attributes ?? [],
                isLoading: isLoading,
                usesCustomAttributes: source != null
            }}
        />
    );
}
