import {Button, Popover} from "antd";
import React from "react";

export function AddFilterButton({id, onVisibleChange, contentVisible, content, title="Add filter"}) {
    return (
        <Popover
            visible={contentVisible}
            destroyTooltipOnHide={true}
            trigger="click"
            title={title}
            placement="bottom"
            content={content}
            onVisibleChange={onVisibleChange}
        >
            <Button
                id={id ?? "add-filter-button"}
                icon={<img src={process.env.PUBLIC_URL + "/images/filter_list-24px.svg"}/>}
                size="small"
            >
                Add filter
            </Button>
        </Popover>
    );
}