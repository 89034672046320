import ImageViewer from "../../ImageViewer/ImageViewer";
import {ViewHierarchy} from "../../ImageViewer/Hierarchies/ViewHierarchy";
import HierarchyNode from "./HierarchyNode";
import ImageViewerObject from "../ImageViewerObject";
import HierarchiesService from "../../../services/HierarchiesService";

export default class Hierarchy {
  public id: string;
  public name: string;
  public hidden: boolean;
  public nodes: HierarchyNode[];
  public imageViewer: ImageViewer;
  public hierarchyView: ViewHierarchy;

  constructor({id, name, hidden, nodes, imageViewer, hierarchyView}: {
    id: string, name: string, hidden: boolean, nodes: HierarchyNode[], imageViewer: ImageViewer, hierarchyView: ViewHierarchy,
  }) {
    this.id = id;
    this.name = name;
    this.hidden = hidden;
    this.nodes = nodes;
    this.imageViewer = imageViewer;
    this.hierarchyView = hierarchyView;
  }

  async setHiddenStatus(status: boolean) {
    await HierarchiesService.hideHierarchy(this.id, status);
  }

  getNodeById(nodeId: string) {
    return this.nodes.find(node => node.id === nodeId) ?? null;
  }

  getRootNode() {
    return this.nodes.find(node => node.parent_node_id === null);
  }

  /**
   * returns null if the node doesn't exist
   */
  findNode(text: string, label: string) {
    return this.nodes.find(node => node.text === text && node.label === label) ?? null;
  }

  /**
   * returns null if there is no such node
   */
  findNodeByObject(obj: ImageViewerObject) {
    return this.nodes.find(node => node.matchesObject(obj)) ?? null;
  }

  isValidSubnodesAttributeValue(value: string): boolean {
    if (value.trim().length === 0) return false;
    return value.split(",").every(s => {
      const spl = s.split("|");
      if (spl.length !== 2) return false;
      return spl[0].trim().length > 0 && spl[1].trim().length > 0;
    });
  }

  hasSubnodesAttribute(obj: ImageViewerObject): boolean {
    return this.getSubnodesAttributesKeys(obj).length > 0;
  }

  getSubnodesAttributesKeys(obj: ImageViewerObject): string[] {
    return obj.metadata.attributes.filter(attr => this.isValidSubnodesAttributeValue(attr.value)).map(attr => attr.key);
  }
}
