import React from "react";
import ReactDOM from "react-dom/client";
import "./index.less";
import ProjectsListContainer from "./containers/ProjectsListContainer";
import { Provider } from "react-redux";
import configureStore, { history } from "./configureStore";
import {ConnectedRouter, push} from "connected-react-router";
import { Switch, Route, Redirect, withRouter } from "react-router";
import NewProjectContainer from "./containers/NewProjectContainer";
import { ProjectsLayout } from "./components/ProjectsLayout";
import { RouteWithLayout } from "./components/RouteWithLayout";
import { ProjectWorkspace } from "./components/ProjectWorkspace";
import { ProjectWorkspaceLayout } from "./components/ProjectWorkspaceLayout";
import { DocumentViewLayout } from "./components/DocumentViewLayout";
import UploadDocumentsContainer from "./containers/UploadDocumentsContainer";
import StatisticsContainer from "./containers/StatisticsContainer";
import ExtractTagsContainer from "./containers/ExtractTagsContainer";
import ExploreResultsContainer from "./containers/ExploreResultsContainer";
import FilesNavigationContainer from "./containers/FilesNavigationContainer";
import SamplesContainer from "./containers/SamplesContainer";
import FinalResultsContainer from "./containers/FinalResultsContainer";
import {FinalResultViewer} from "./components/FinalResultViewer";
import {SampleViewer} from "./components/SampleViewer";
import {ObjectDetectionSampleViewer} from "./components/ObjectDetectionSampleViewer";
import "react-dropdown-tree-select/dist/styles.css";
import {Login} from "./components/Auth/Login";
import axios from "axios";
import AuthService from "./services/AuthService";
import {API_URL, API_V2_URL, KEYCLOAK_ENABLED, UI_CONFIG, VIEW_WORKSPACE_ID_COOKIE_NAME} from "./constants";
import {UIConfigContext} from "@digatex/digatex-ui-lib";
import {WorkspacesList} from "./components/Workspaces/WorkspacesList";
import {Workspace} from "./components/Workspaces/Workspace";
import {UserViewLayout} from "./components/UserView/UserViewLayout";
import UserViewSearch from "./components/UserView/UserViewSearch";
import UserViewExploreResultsRun from "./components/UserView/UserViewExploreResultsRun";
import {UserViewFinalizeResults} from "./components/UserView/UserViewFinalizeResults";
import {UserViewExploreResultsRunsList} from "./components/UserView/UserViewExploreResultsRunsList";
import {authHeader} from "./Utilities";
import {UsersList} from "./components/Users/UsersList";
import {NewUser} from "./components/Users/NewUser";
import {EditUser} from "./components/Users/EditUser";
import {ViewDocument} from "./components/ViewDocument";
import ExploreTagsContainer from "./containers/ExploreTagsContainer";
import { Settings } from "./components/Settings";
import {SearchContextProvider} from "./contexts/SearchContext";
import WorkspaceViewModeService from "./services/WorkspaceViewModeService";
import {OrganizationsList} from "./components/Organizations/OrganizationsList";
import {NewOrganization} from "./components/Organizations/NewOrganization";
import {ViewOrganization} from "./components/Organizations/ViewOrganization";
import {EditOrganization} from "./components/Organizations/EditOrganization";
import {PasswordReset} from "./components/Auth/PasswordReset";
import {AuthContextProvider} from "./contexts/AuthContext";
import {GlobalSettings} from "./components/GlobalSettings";
import {Logout} from "./components/Auth/Logout";
import {UserViewConfigureReports} from "./components/UserView/UserViewConfigureReports";
import {GeneralLink} from "./components/GeneralLink";
import UserViewComments from "./components/UserView/Comments/UserViewComments";
import {Jobs, ProjectJobs} from "./components/Jobs/Jobs";
import {DocumentRegistryView} from "./components/DocumentRegistry/DocumentRegistryView";

axios.defaults.withCredentials = true;

// trim copied text to prevent avoid spaces, e.g. when copying text from table cells
document.addEventListener("copy", function(e) {
    const text_only = document.getSelection().toString().trim();
    const clipdata = e.clipboardData || window.clipboardData;
    clipdata.setData("text/plain", text_only);
    clipdata.setData("text/html", text_only);
    e.preventDefault();
});

const store = configureStore();

if (KEYCLOAK_ENABLED) {
  axios.interceptors.request.use((config) => {
    if (AuthService.isAuthenticated()) {
      const cb = () => {
        config.headers = { ...config.headers, ...AuthService.headers()	};
        return Promise.resolve(config);
      };
      return AuthService.updateToken(cb);
    }
  });
}


axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (axios.isCancel(error)) {
        return Promise.reject(error);
      }
      const originalRequest = error.config;

      if (!KEYCLOAK_ENABLED) {
        if (error.response.status === 401 && originalRequest.url.endsWith("/auth/refresh_token")) {
          AuthService.logout();
          if (!window.location.href.endsWith("/login")) {
            store.dispatch(push({pathname: "/login", state: {from: {pathname: window.location.href}}}));
          }
          return Promise.reject(error);
        }

        if (AuthService.isAuthenticated() && error.response.status === 401 && !originalRequest._retry) {
          const currentUser = AuthService.getCurrentUser();
          const refreshToken = currentUser.refresh_token;
          originalRequest._retry = true;
          return axios.post(API_URL + "/auth/refresh_token", {}, {
            headers: {
              "Authorization": "Bearer " + refreshToken
            }
          }).then(res => {
            if (res.status === 200) {
              const newAccessToken = res.data.access_token;
              const updatedItem = {
                "access_token": newAccessToken,
                "refresh_token": refreshToken
              };
              localStorage.setItem("user", JSON.stringify(updatedItem));
              const newRequest = {...originalRequest};
              newRequest.headers["Authorization"] = "Bearer " + newAccessToken;
              return axios(originalRequest);
            }
          });
        }
        if (error.response.status === 401) {
          AuthService.logout();
          if (!window.location.href.endsWith("/login")) {
            store.dispatch(push({pathname: "/login", state: {from: {pathname: window.location.href}}}));
          }
        }
      }

      if (error.response.status === 403 && error.response.data.message === `${VIEW_WORKSPACE_ID_COOKIE_NAME} is required`
          && !originalRequest._retryLoadCurWorkspace) {

        originalRequest._retryLoadCurWorkspace = true;

        return axios.get(API_URL + "/workspaces").then(response => {
          if (response.data.length === 0) {
            WorkspaceViewModeService.exitWorkspace();
          } else {
            const sorted = response.data.sort((a, b) => b.id.localeCompare(a.id));

            WorkspaceViewModeService.enterWorkspace(sorted[0].id);
          }

          return axios(originalRequest);
        });
      }
      return Promise.reject(error);
    }
);

axios.defaults.headers = {
  "Cache-Control": "no-cache",
  "Pragma": "no-cache",
  "Expires": "0",
};

export const API_V2 = axios.create({
  baseURL:API_V2_URL,
});

axios.interceptors.request.handlers.forEach((handler) => {
  API_V2.interceptors.request.use(handler.fulfilled, handler.rejected);
});

axios.interceptors.response.handlers.forEach((handler) => {
  API_V2.interceptors.response.use(handler.fulfilled, handler.rejected);
});


class App extends React.Component {
  state = {
    currentUser: null,
  };

  handleUrl = () => {
    if (!["/login", "/auth/reset_password", "/auth/create_password"].includes(this.props.location.pathname))
      this.loadUser();
  };

  componentDidMount() {
    this.handleUrl();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) this.handleUrl();
  }

  loadUser = () => {
    axios.get(API_URL + "/get_current_user", {headers: authHeader()}).then(res => {
      this.setState({currentUser: res.data});
    }).catch(err => {
      // commented as it trigger endless page reloading and incorrect redirect after login

      // const needToReload = !['/login', '/'].includes(this.props.location.pathname)
      //
      // this.props.history.push('/login');
      //
      // if (needToReload) {
      //   window.location.reload();
      // }

      // commented as it show message appearing after redirecting to the login screen
      // message.error('Failed to load user info');
    });
  };

  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact
            path="/"
            render={() => (
              <>
              {
                this.state.currentUser ?
                ((this.state.currentUser.roles.includes("admin") || this.state.currentUser.roles.includes("analyst"))
                    && !(this.state.currentUser.roles.includes("admin") && WorkspaceViewModeService.isInViewingMode()) ?
                    <Redirect to="/projects" />
                  :
                    <Redirect to="/user_view" />
                )
                  :
                null
              }
              </>
            )}
          />

          <RouteWithLayout layout={UserViewLayout} path="/user_view/search" component={UserViewSearch} />
          <RouteWithLayout layout={UserViewLayout} path="/user_view/explore_results/:runId" component={UserViewExploreResultsRun} />
          <RouteWithLayout layout={UserViewLayout} path="/user_view/explore_results" component={UserViewExploreResultsRunsList} />
          <RouteWithLayout layout={UserViewLayout} path="/user_view/document_registry" component={DocumentRegistryView} />

          <RouteWithLayout layout={UserViewLayout} path="/user_view/finalize_results" component={UserViewFinalizeResults} />

          <RouteWithLayout layout={UserViewLayout} path="/user_view/comments" component={UserViewComments} />

          <RouteWithLayout layout={UserViewLayout} exact path="/user_view/users/new" component={NewUser} root="/user_view/" />
          <RouteWithLayout layout={UserViewLayout} exact path="/user_view/users/:userId/edit" component={EditUser} root="/user_view/" />
          <RouteWithLayout layout={UserViewLayout} exact path="/user_view/users" component={UsersList} root="/user_view/" />

          <RouteWithLayout layout={UserViewLayout} exact path="/user_view/organizations/:organizationId" component={ViewOrganization} root="/user_view/" />
          <RouteWithLayout layout={UserViewLayout} exact path="/user_view/organizations/:organizationId/edit" component={EditOrganization} root="/user_view/" />
          <RouteWithLayout layout={UserViewLayout} exact path="/user_view/organizations" component={OrganizationsList} root="/user_view/" />

          <RouteWithLayout layout={UserViewLayout} exact path="/user_view/configure_reports" component={UserViewConfigureReports} root="/user_view/" />

          <Route exact path="/user_view"
             render={(props) => (<Redirect to={"/user_view/search"} />)}
          />

          <RouteWithLayout layout={ProjectsLayout} exact path="/projects" component={ProjectsListContainer} />
          <RouteWithLayout layout={ProjectsLayout} exact path="/projects/new" component={NewProjectContainer} />
          <RouteWithLayout layout={ProjectsLayout} exact path="/workspaces" component={WorkspacesList} />
          <RouteWithLayout layout={ProjectsLayout} exact path="/jobs" component={Jobs} />
          <RouteWithLayout layout={ProjectsLayout} exact path="/workspace/:workspaceId" component={Workspace} />

          <RouteWithLayout layout={ProjectsLayout} exact path="/users/new" root="/" component={NewUser} />
          <RouteWithLayout layout={ProjectsLayout} exact path="/users/:userId/edit" root="/" component={EditUser} />
          <RouteWithLayout layout={ProjectsLayout} exact path="/users" root="/" component={UsersList} />

          <RouteWithLayout layout={ProjectsLayout} exact path="/organizations/new" root="/" component={NewOrganization} />
          <RouteWithLayout layout={ProjectsLayout} exact path="/organizations/:organizationId" root="/" component={ViewOrganization} />
          <RouteWithLayout layout={ProjectsLayout} exact path="/organizations/:organizationId/edit" root="/" component={EditOrganization} />
          <RouteWithLayout layout={ProjectsLayout} exact path="/organizations" root="/" component={OrganizationsList} />
          <RouteWithLayout layout={ProjectsLayout} exact path="/global_settings" root="/" component={GlobalSettings} />
          <RouteWithLayout
            layout={ProjectWorkspaceLayout}
            path="/project/:projectId/upload_documents"
            component={UploadDocumentsContainer}
          />
          <RouteWithLayout
            layout={ProjectWorkspaceLayout}
            path="/project/:projectId/statistics"
            component={StatisticsContainer}
          />

          <RouteWithLayout
              layout={ProjectWorkspaceLayout}
              path="/project/:projectId/extract_tags/new_text_rule/:textRule"
              component={ExtractTagsContainer}
          />

          <RouteWithLayout
            layout={ProjectWorkspaceLayout}
            path="/project/:projectId/extract_tags"
            component={ExtractTagsContainer}
          />

          <RouteWithLayout
              layout={ProjectWorkspaceLayout}
              path="/project/:projectId/samples/view_sample/:sampleId"
              component={SampleViewer}
          />

          <RouteWithLayout
              layout={ProjectWorkspaceLayout}
              path="/project/:projectId/samples"
              component={SamplesContainer}
          />

          <RouteWithLayout
              layout={ProjectWorkspaceLayout}
              path="/project/:projectId/object_detection_samples/view_sample/:sampleId"
              component={ObjectDetectionSampleViewer}
          />

          <RouteWithLayout
            layout={DocumentViewLayout}
            path="/project/:projectId/explore_results/:runId/view_document/:documentId"
            component={ViewDocument}
          />

          <RouteWithLayout
            layout={ProjectWorkspaceLayout}
            path="/project/:projectId/explore_results/:runId"
            component={ExploreResultsContainer}
          />

          <RouteWithLayout
            layout={ProjectWorkspaceLayout}
            path="/project/:projectId/explore_results"
            component={ExploreResultsContainer}
          />

          <RouteWithLayout
              layout={DocumentViewLayout}
              path="/project/:projectId/final_results/view_result/:finalResultId"
              component={FinalResultViewer}
          />

          <RouteWithLayout
              layout={DocumentViewLayout}
              path="/project/:projectId/general_link"
              component={GeneralLink}
          />

          <RouteWithLayout
              layout={ProjectWorkspaceLayout}
              path="/project/:projectId/final_results"
              component={FinalResultsContainer}
          />

          <RouteWithLayout
              layout={ProjectWorkspaceLayout}
              path="/project/:projectId/explore_files"
              component={FilesNavigationContainer}
          />

          <RouteWithLayout
              layout={ProjectWorkspaceLayout}
              path="/project/:projectId/explore_tags"
              component={ExploreTagsContainer}
          />

          <RouteWithLayout
              layout={ProjectWorkspaceLayout}
              path="/project/:projectId/settings"
              component={Settings}
          />

          <RouteWithLayout
              layout={ProjectWorkspaceLayout}
              path="/project/:projectId/jobs"
              component={ProjectJobs}
          />

          <Route exact path="/project/:projectId"
            render={(props) => (<Redirect to={props.location.pathname + "/upload_documents"} />)}
          />
          <RouteWithLayout layout={ProjectWorkspaceLayout} path="/project/:projectId" component={ProjectWorkspace} />

          {!KEYCLOAK_ENABLED && [
            <Route exact path="/login" component={Login}/>,
            <Route exact path="/auth/reset_password"
                   render={(props) => (<PasswordReset {...props} mode="reset"/>)}/>,
            <Route exact path="/auth/create_password"
                   render={(props) => (<PasswordReset {...props} mode="create"/>)}/>
          ]}
          <Route exact path="/logout" component={Logout} />

          <Route render={props => (<Redirect to={{
            pathname: "/",
            state: null
          }} {...props}/>)}/>
        </Switch>
      </div>
    );
  }
}

const AppWithRouter = withRouter(App);

const root = ReactDOM.createRoot(document.getElementById("root"));

AuthService.init(_ => {
  root.render(
      <UIConfigContext.Provider value={UI_CONFIG}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AuthContextProvider>
            <SearchContextProvider>
              <AppWithRouter />
            </SearchContextProvider>
          </AuthContextProvider>
        </ConnectedRouter>
        </Provider>
      </UIConfigContext.Provider>
  );
});
