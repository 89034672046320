class UserPreferencesService {
  setDrawingImageQuality(quality) {
    localStorage.setItem("image_quality", quality);
  }

  getDrawingImageQuality() {
    return localStorage.getItem("image_quality") || "medium";
  }

  setThumbnailsSize(size) {
    localStorage.setItem("thumbnails_size", size);
  }

  getThumbnailsSize() {
    return parseInt(localStorage.getItem("thumbnails_size") ?? "2");
  }

  setMiniMapState(miniMapState) {
    localStorage.setItem("minimap_state", miniMapState);
  }

  getMiniMapState() {
    return localStorage.getItem("minimap_state") ?? "shown";
  }

  setHierarchyAttributesSelect(workspaceId, selectValue) {
    const currentData = JSON.parse(localStorage.getItem("selectedHierarchyAttributes") || "{}");
    currentData[workspaceId] = selectValue;
    localStorage.setItem("selectedHierarchyAttributes", JSON.stringify(currentData));
  }
  
  getHierarchyAttributesSelect(workspaceId) {
    const currentData = JSON.parse(localStorage.getItem("selectedHierarchyAttributes") || "{}");
    return currentData[workspaceId];
  }
}

export default new UserPreferencesService();
