import {Filter, FilterMedia, Filters, FiltersList} from "../Plugins/Filters";
import {DocumentRegistryQuery} from "../../../services/DocumentRegistry/DocumentRegistry";
import {Scalar} from "../../../services/Scalar";
import {ReadonlyResource} from "../../../services/Resources";
import {useResource} from "../../../services/useResource";
import {Dep, useDep} from "../../../services/useDep";
import React from "react";
import {SortAscendingOutlined, SortDescendingOutlined} from "@ant-design/icons";

export class RegistryFilters extends Scalar<Filters<GridFilter>> {
}

export function useRegistryFilters(res: ReadonlyResource<RegistryFilters>): Dep<Filters<GridFilter>> {
    const wrapper = useResource(res);
    return useDep(() => wrapper.value(), [wrapper]);
}

export type GridFilter = Filter & {
    build: (query: DocumentRegistryQuery) => DocumentRegistryQuery;

    getColumnSortOrder: (columnId: string) => 1 | -1 | 0;
}

export abstract class GridFilterBase implements GridFilter {
    readonly key: string;
    protected readonly name: string;
    protected readonly value: any;

    constructor(key: string, value: any, name: string) {
        this.key = key;
        this.value = value;
        this.name = name;
    }

    addTo(filters: FiltersList<this>): void {
        filters.replaceByKeyOrInsert(this);
    }

    print<T extends FilterMedia>(media: T): T {
        return media.setDisplayValue(this.value).setDisplayName(this.name);
    }

    abstract build(query: DocumentRegistryQuery): DocumentRegistryQuery;

    getColumnSortOrder(columnId: string): 1 | -1 | 0 {
        return 0;
    }
}

abstract class GridFilterSort extends GridFilterBase {
    private readonly columnId: string;

    constructor(key: string, value: any, name: string, columnId: string) {
        super(key, value, name);
        this.columnId = columnId;
    }

    getColumnSortOrder(columnId: string): 1 | -1 | 0 {
        if (this.columnId === columnId) {
            return this.value;
        } else {
            return 0;
        }
    }

    print<T extends FilterMedia>(media: T): T {
        return super.print(media)
            .setColor("green")
            .setDisplayValue(this.value === 1 ? "asc" : "desc")
            .setIcon(this.value === 1 ? <SortAscendingOutlined /> : <SortDescendingOutlined />)
        ;
    }
}

export class ColumnFilter extends GridFilterBase implements GridFilter {
    build(query: DocumentRegistryQuery): DocumentRegistryQuery {
        return query.withPartialFieldFilter(this.key, this.value);
    }
}

export class IdFilter extends GridFilterBase {
    constructor(value: any) {
        super("id-filter", value, "Id");
    }

    build(query: DocumentRegistryQuery): DocumentRegistryQuery {
        return query.withIdFilter(this.value);
    }
}

export class ScopeFilter extends GridFilterBase {
    private readonly scopeName: string;

    constructor(value: any, scopeName: string) {
        super("scope-filter", value, "Scope");
        this.scopeName = scopeName;
    }

    build(query: DocumentRegistryQuery): DocumentRegistryQuery {
        return query.withScopeFilter(this.value);
    }

    print<T extends FilterMedia>(media: T): T {
        return super.print(media).setDisplayValue(this.scopeName);
    }
}

export class JustCreatedFilter extends IdFilter {
    print<T extends FilterMedia>(media: T): T {
        return super.print(media).setDisplayValue("Just created");
    }
}

export class FileNameFilter extends GridFilterBase {
    constructor(value: any, filterAlias: string) {
        super("file-name-filter", value, filterAlias);
    }

    build(query: DocumentRegistryQuery): DocumentRegistryQuery {
        return query.withPartialFileNameFilter(this.value);
    }
}

export class FileNameSort extends GridFilterSort implements GridFilter {
    constructor(value: any, filterAlias: string, columnId: string) {
        super("file-name-sort", value, filterAlias, columnId);
    }

    build(query: DocumentRegistryQuery): DocumentRegistryQuery {
        return query.withFileNameSort(this.value);
    }
}

export class PageNumberFilter extends GridFilterBase {
    constructor(value: any, filterAlias: string) {
        super("page-number-filter", value, filterAlias);
    }

    build(query: DocumentRegistryQuery): DocumentRegistryQuery {
        return query.withExactPageNumberFilter(this.value);
    }
}

export class PageNumberSort extends GridFilterSort implements GridFilter {
    constructor(value: any, filterAlias: string, columnId: string) {
        super("page-number-sort", value, filterAlias, columnId);
    }

    build(query: DocumentRegistryQuery): DocumentRegistryQuery {
        return query.withPageNumberSort(this.value);
    }
}

export class FieldSort extends GridFilterSort implements GridFilter {
    build(query: DocumentRegistryQuery): DocumentRegistryQuery {
        return query.withFieldSort(this.key, this.value);
    }
}

export class SetSort extends GridFilterSort implements GridFilter {
    private readonly setId: string;

    constructor(setId: string, value: any, filterAlias: string, columnId: string) {
        super(`set-${setId}-sort`, value, filterAlias, columnId);
        this.setId = setId;
    }

    build(query: DocumentRegistryQuery): DocumentRegistryQuery {
        return query.withSetSort(this.setId, this.value);
    }
}

export class SetFilter extends GridFilterBase implements GridFilter {
    private readonly setId: string;

    constructor(setId: string, value: any, filterAlias: string) {
        super(`set-${setId}-filter`, value, filterAlias);
        this.setId = setId;
    }

    build(query: DocumentRegistryQuery): DocumentRegistryQuery {
        if (this.value) {
            return query.withSetFilter(this.setId);
        } else {
            return query.withSetFilterNot(this.setId);
        }
    }

    print<T extends FilterMedia>(media: T): T {
        if (this.value) {
            return super.print(media).setDisplayName("In").setDisplayValue(this.name);
        } else {
            return super.print(media).setDisplayName("Not in").setDisplayValue(this.name);
        }
    }
}
