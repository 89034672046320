import React, {RefObject, useEffect, useRef, useState} from "react";
import {Docks, DockSlots, DocksState, DockTabItem, useDocksState} from "./Docks";
import {InstalledPluginsFor, PluginAnonymous, PluginSource, PluginSourceParallel} from "../../services/Plugins";
import {Resource, Resources, ResourcesSource, ResType} from "../../services/Resources";
import {Subscriptions} from "../../services/Subscriptions";
import {PluginDocDataGrid} from "./Plugins/DocDataGrid/PluginDocDataGrid";
import {PluginDocQuickView} from "./Plugins/PluginDocQuickView";
import {PluginCanvasGroup} from "../ResponsiveCanvas/Plugins/PluginCanvasGroup";
import {DocumentRegistries} from "../../services/DocumentRegistry/DocumentRegistry";
import {CurrentRegistry} from "./Resources/CurrentRegistry";
import {DocumentRegistryItemInspectors} from "./Resources/DocumentRegistryItemInspectors";
import {PluginDocAssociations} from "./Plugins/PluginDocAssociations";
import {PluginPivotTable} from "./Plugins/PluginPivotTable";
import {PluginFileSystem} from "./Plugins/PluginFileSystem";
import {PluginScopes} from "./Plugins/Scopes/PluginScopes";
import {GridFilter, RegistryFilters} from "./Resources/RegistryFilters";
import {Filters} from "./Plugins/Filters";

export interface DocumentRegistryViewRef extends ResourcesSource {
    addDock(slot: DockSlots, control: DockTabItem): void;

    add(subscription: Unsub): void;
}

export function DocumentRegistryView() {
    return (
        <DocumentRegistryInner
            plugins={new PluginSourceParallel([
                new PluginAnonymous("default-resources", {
                    async build(target: DocumentRegistryViewRef): Promise<void> {
                        const registries = new DocumentRegistries();
                        target.res(CurrentRegistry).set(new CurrentRegistry(registries.main()));
                        target.res(CurrentRegistry).effect(registry => {
                           registry.value().columns().reload();
                           registry.value().sets().reload();
                           registry.value().scopes().reload();
                           registry.value().presets().reload();
                        });
                        target.res(DocumentRegistryItemInspectors).set(new DocumentRegistryItemInspectors()).seal();
                        target.res(RegistryFilters).set(new RegistryFilters(new Filters<GridFilter>())).seal();
                    }
                }),
                new PluginCanvasGroup(),

                new PluginDocDataGrid(),
                new PluginScopes(),
                new PluginPivotTable(),
                new PluginFileSystem(),
                new PluginDocQuickView(),
                new PluginDocAssociations(),
            ])}
        />
    );
}

type DocksStateLimited<TSlot> = Omit<DocksState<TSlot>, "clear">

class DocumentRegistryViewRefDefault implements DocumentRegistryViewRef {
    private readonly docks: RefObject<DocksStateLimited<DockSlots>>;
    private readonly installedPlugins = new InstalledPluginsFor<DocumentRegistryViewRef>(this);
    private readonly resources = new Resources();
    private readonly subs = new Subscriptions();

    constructor(docks: RefObject<DocksStateLimited<DockSlots>>) {
        this.docks = docks;
    }

    addDock(slot: DockSlots, control: DockTabItem): void {
        this.docks.current?.addItem(slot, control);
    }

    unmount(): void {
        this.subs.unsubscribe();
    }

    async installPlugins(plugins: PluginSource<DocumentRegistryViewRef>): Promise<void> {
        await plugins.addTo(this.installedPlugins);
        await this.installedPlugins.finish();
    }

    res<T>(type: ResType<T>): Resource<T> {
        return this.resources.res(type);
    }

    add(sub: Unsub): void {
        this.subs.add(sub);
    }
}

interface DocumentRegistryInnerProps {
    plugins: PluginSource<DocumentRegistryViewRef>;
}

function DocumentRegistryInner({plugins}: DocumentRegistryInnerProps) {
    const [isInitializing, setInitializing] = useState<boolean>(true);
    const docksState = useDocksState<DockSlots>();
    const docksStateRef = useRef(docksState);
    const registryRef = useRef<DocumentRegistryViewRefDefault>();

    useEffect(() => {
        docksStateRef.current = docksState;
    }, [docksState]);

    useEffect(() => {
        registryRef.current = new DocumentRegistryViewRefDefault(docksStateRef);
        registryRef.current?.installPlugins(plugins).then(_ => {
            setInitializing(false);
        });

        return () => {
            registryRef.current?.unmount();
            docksStateRef.current?.clear();
        };
    }, []);

    return (
        <>
            <Docks
                state={docksState}
                tools={[]}
                resizerClassName={"default"}
            />
        </>
    );
}

interface Unsub {
    unsubscribe(): void;
}