import React, { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router";
import { useFetch } from "../../../../hooks/useFetch";
import CacheService from "../../../../services/CacheService";
import HierarchiesService from "../../../../services/HierarchiesService";
import { message, Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { CustomObjectAttributesSource } from "./CustomAttributesSource";
import ImageViewer from "../../ImageViewer";
import { NodeAttributesSource } from "./Sources/NodeAttributesSource";
import { memoized } from "../../../../UtilitiesTs";
import UserPreferencesService from "../../../../services/UserPreferencesService";

interface OptionType {
    label: string;
    value: string;
    source: CustomObjectAttributesSource | null;
}

interface ObjectDataSourceSelectProps {
    iv: ImageViewer;
    onChange: (source: CustomObjectAttributesSource | null) => void;
}

export const defaultOption: OptionType = {
    label: "Current Drawing",
    value: "",
    source: null,
};

export function CustomAttributesSourceSelect({
    iv,
    onChange,
}: ObjectDataSourceSelectProps) {
    const [fetchTrigger, setFetchTrigger] = useState(0);
    const [options, setOptions] = useState<OptionType[]>([defaultOption]);
    const [workspaceId, setWorkspaceId] = useState<string | null>(null);
    const [selectedValue, setSelectedValue] = useState(
        () => defaultOption.value
    );
    const params = useParams<{ projectId: string }>();

    useEffect(() => {
        const subscription = iv.hierarchyListChanged$.subscribe(() => {
            setFetchTrigger((prev) => prev + 1);
        });
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    useFetch(
        () =>
            CacheService.getProjectData(params.projectId).then(
                (projectData) => {
                    return HierarchiesService.fetchHierarchies(
                        projectData.workspace_id
                    ).then((res) => {
                        const hierarchies = res.data.filter(item => !item.hidden).map((hierarchy) => ({
                            label: hierarchy.name,
                            value: hierarchy.id,
                            source: new NodeAttributesSource(
                                iv,
                                hierarchy.id,
                                memoized(() =>
                                    HierarchiesService.fetchSystemAttributes(
                                        hierarchy.id
                                    )
                                )
                            ),
                        }));
                        return {
                            options: [defaultOption, ...hierarchies],
                            projectData,
                        };
                    });
                }
            ),
        ({ options, projectData }) => {
            setOptions(options);
            setWorkspaceId(projectData.workspace_id);
            const hierarchyAttributesSelect = UserPreferencesService.getHierarchyAttributesSelect(projectData.workspace_id);
            setSelectedValue(
                options.find((opt) => opt.value === hierarchyAttributesSelect)
                ? hierarchyAttributesSelect
                : defaultOption.value
            );
            const savedOption = 
                options.find((opt) => opt.value === hierarchyAttributesSelect) 
                || defaultOption;
            onChange(savedOption.source);
        },
        () => message.error("Failed to load hierarchies list"),
        [params.projectId, fetchTrigger]
    );

    const handleSelectChange = (_: any, option: OptionType) => {
        setSelectedValue(option.value);
        if (workspaceId) {
            UserPreferencesService.setHierarchyAttributesSelect(
                workspaceId,
                option.value
            );
        }
        onChange(option.source);
    };

    const dropdownMinWidth = useMemo(
        () => {
            const maxLength = Math.max(...options.map(x => x.label.length));
            return Math.max(128, Math.min(maxLength * 8, 512));
        },
        [options]
    );

    return (
        <Select
            style={{
                minWidth: "128px",
                maxWidth: "256px",
            }}
            dropdownStyle={{
                minWidth: dropdownMinWidth + "px"
            }}
            size={"small"}
            onChange={
                handleSelectChange as SelectProps<
                    OptionType | string
                >["onChange"]
            }
            options={options}
            value={selectedValue}
        />
    );
}
