import React, {useMemo} from "react";
import { Breadcrumb } from "antd";
import HierarchyNode from "../../ImageViewerHandlers/Hierarchy/HierarchyNode";
import {truncate} from "../../../Utilities";

interface BreadcrumbsProps {
    currentNode: HierarchyNode | null;
    onClick: (node: HierarchyNode) => void;
}

export const HierarchyBreadcrumb: React.FC<BreadcrumbsProps> = ({
    currentNode,
    onClick,
}) => {
    const breadcrumbNodes = useMemo<HierarchyNode[]>(() => {
        if (currentNode == null) {
            return [];
        }
        return [
            currentNode,
            ...currentNode.getPathToRoot(),
        ].reverse();
    }, [currentNode]);

    if (breadcrumbNodes.length <= 1) {
        return null;
    }

    return (
        <Breadcrumb style={{ minHeight: "40px", whiteSpace: "nowrap" }}>
            {breadcrumbNodes.map((node) => (
                <Breadcrumb.Item key={node.id} onClick={() => onClick(node)}>
                    <a>{truncate(node.text, 40)}</a>
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
};
