import React, {ReactChild, useEffect, useMemo, useState} from "react";
import {Button, Card, CardProps, Row, Space, Tabs} from "antd";
import SplitPane from "react-split-pane";
import {FitParentSize} from "../Misc/FitParentSize";
import {ImmortalAutoSizer} from "../Misc/ImmortalAutoSizer";
import {TabsProps} from "antd/lib/tabs";
import {FullscreenOutlined, SettingOutlined} from "@ant-design/icons";
import {AutoSizer} from "react-virtualized";


export function Docks({state, tools, resizerClassName}: DocksProps<DockSlots>) {
    const dock = (slot: DockSlots) => new SmartDock(slot, state);
    return (
        <ImmortalAutoSizer>
            {({width, height}) => (
                <Row style={{width: width + "px", height: height + "px"}}>
                    <SplitPane
                        style={{position: "relative"}}
                        resizerClassName={resizerClassName}
                        split="vertical"
                        maxSize={width * 0.5}
                        minSize={width * 0.15}
                        defaultSize={width * 0.25}

                        pane1Style={hideIf(dock("left-ur").isHidden() && dock("left-br").isHidden())}
                        resizerStyle={hideIf(dock("left-ur").isHidden() && dock("left-br").isHidden())}
                    >
                        <SplitPane
                            resizerClassName={resizerClassName}
                            style={{position: "relative"}}
                            split="horizontal"

                            primary={setPrimaryIf(dock("left-ur").isHidden())}

                            maxSize={height * 0.75}
                            minSize={height * 0.25}
                            defaultSize={height * 0.5}

                            pane1Style={hideIf(dock("left-ur").isHidden())}
                            pane2Style={hideIf(dock("left-br").isHidden())}
                            resizerStyle={hideIf(dock("left-ur").isHidden() || dock("left-br").isHidden())}
                        >
                            <Dock id={"left-ur"} items={dock("left-ur").items()}/>
                            <Dock id={"left-br"} items={dock("left-br").items()}/>
                        </SplitPane>
                        <div style={{width: "100%", height: "100%"}}>
                            <ImmortalAutoSizer>
                                {({width, height}) => (
                                    <div style={{width, height}}>
                                        <SplitPane
                                            resizerClassName={resizerClassName}
                                            style={{position: "relative"}}

                                            pane2Style={hideIf(dock("right-ul").isHidden() && dock("right-bl").isHidden())}
                                            resizerStyle={hideIf(dock("right-ul").isHidden() && dock("right-bl").isHidden())}

                                            split="vertical"
                                            primary={"second"}
                                            maxSize={width * 0.75}
                                            minSize={width * 0.25}
                                        >
                                            <MainPane
                                                items={dock("main").items()}
                                                toolbar={
                                                    <Toolbar tools={tools}/>
                                                }
                                            />
                                            <SplitPane
                                                resizerClassName={resizerClassName}
                                                style={{position: "relative"}}
                                                split="horizontal"

                                                maxSize={height * 0.75}
                                                minSize={height * 0.25}
                                                defaultSize={height * 0.5}

                                                primary={setPrimaryIf(dock("right-ul").isHidden())}
                                                pane1Style={hideIf(dock("right-ul").isHidden())}
                                                pane2Style={hideIf(dock("right-bl").isHidden())}
                                                resizerStyle={hideIf(dock("right-ul").isHidden() || dock("right-bl").isHidden())}
                                            >
                                                <Dock id={"right-ul"} items={dock("right-ul").items()}/>
                                                <Dock id={"right-bl"} items={dock("right-bl").items()}/>
                                            </SplitPane>
                                        </SplitPane>
                                    </div>
                                )}
                            </ImmortalAutoSizer>
                        </div>
                    </SplitPane>
                </Row>
            )}
        </ImmortalAutoSizer>
    );
}

export type DockSlots =
    "left-ur"
    | "left-br"
    | "right-ul"
    | "right-bl"
    | "main"
// | 'left-ul'
// | 'left-bl'
// | 'right-ur'
// | 'right-br'

export function useDocksState<TSlots>(): DocksState<TSlots> {
    type State = Map<TSlots, Array<DockTabItem>>;
    const [state, setState] = useState<State>(new Map());
    return useMemo<DocksState<TSlots>>(() => ({
            addItem(slot: TSlots, control: DockTabItem): void {
                const currentItems = this.items(slot);
                state.set(slot, [...currentItems, control]);
                setState((prev: State) => {
                    prev.set(slot, [...currentItems, control]);
                    return new Map<TSlots, Array<DockTabItem>>(prev.entries());
                });
            },
            items(slot: TSlots): Array<DockTabItem> {
                return state.get(slot) ?? [];
            },
            clear(): void {
                state.clear();
                setState(new Map());
            }
        }
    ), [state]);
}

export interface DocksState<TSlot> {
    addItem(slot: TSlot, control: DockTabItem): void

    items(slot: TSlot): Array<DockTabItem>

    clear(): void
}

export class SmartDock<TSlot> {
    private readonly slot: TSlot;
    private readonly state: DocksState<TSlot>;

    constructor(slot: TSlot, state: DocksState<TSlot>) {
        this.slot = slot;
        this.state = state;
    }

    items() {
        return this.state.items(this.slot);
    }

    isHidden() {
        return this.items().length === 0;
    }

    addControl(control: DockTabItem) {
        this.state.addItem(this.slot, control);
    }
}

interface DocksProps<TDockSlot> {
    state: DocksState<TDockSlot>
    tools: Array<ToolbarTool>
    resizerClassName: string
}

function setPrimaryIf(cond: boolean) {
    return cond ? "first" : "second";
}

function hideIf(cond: boolean) {
    return {display: cond ? "none" : "initial"};
}

interface MainPaneProps {
    items: Array<DockTabItem>
    toolbar: ReactChild
}

function MainPane({items, toolbar}: MainPaneProps) {
    return (
        <div id={"main"} style={{width: "100%", height: "100%"}}>
            <div style={{width: "100%", height: "100%", padding: "8px"}}>
                <DockCard>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: "100%"
                    }}>
                        {/*{toolbar}*/}
                        <div style={{width: "100%", height: "100%"}}>
                            <DockTabs
                                items={items}
                                tabPosition={"bottom"}
                            />
                        </div>
                    </div>
                </DockCard>
            </div>
        </div>
    );
}

interface ToolbarTool {
    order: number | undefined
    key: string
    children: ReactChild
}

interface ToolbarProps {
    tools: Array<ToolbarTool>
}

export function Toolbar({tools = []}: ToolbarProps) {
    tools = tools.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    return (
        <div style={{width: "100%", display: "flex", flexDirection: "row-reverse"}}>
            <div style={{height: "100%"}}>
                <Space style={{width: "100%"}} align={"end"} direction={"horizontal"}>
                    <Button type={"text"} icon={<SettingOutlined/>}/>
                    <Button type={"text"} icon={<FullscreenOutlined/>}/>
                </Space>
            </div>
            <div style={{width: "100%", height: "100%"}}>
                <AutoSizer>
                    {({width}) => (
                        <Space
                            align={"center"}
                            id={"toolbar"}
                            style={{maxWidth: width, overflowX: "auto"}}
                            direction={"horizontal"}
                        >
                            {tools.map(tool => (
                                <React.Fragment key={tool.key}>
                                    {tool.children}
                                </React.Fragment>
                            ))}
                        </Space>
                    )}
                </AutoSizer>
            </div>
        </div>
    );
}

interface DockProps {
    id: string
    items: Array<DockTabItem>
}

function Dock({id, items}: DockProps) {
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                // border: "1px solid black"
            }}
        >
            <div id={id} style={{width: "100%", height: "100%", padding: "8px"}}>
                <DockCard>
                    <DockTabs items={items}/>
                </DockCard>
            </div>
        </div>
    );
}

export interface DockTabItem {
    key: string,
    name: React.ReactNode,
    children: ReactChild,
    forceRender?: boolean
}

interface DockTabsProps extends Omit<TabsProps, "items"> {
    items: Array<DockTabItem>
}

function DockTabs({items, ...rest}: DockTabsProps) {
    const [view, setView] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (items?.length === 1) {
            setView(items[0].key);
        }
        if (items?.length > 1 && items?.find(x => x.key === view) == null) {
            setView(items[items.length - 1].key);
        }
    }, [items]);

    return (
        <Tabs
            size={"small"}
            activeKey={view}
            onChange={e => setView(e)}
            items={items?.map(x => ({
                label: x.name,
                key: x.key,
                children: (
                    <FitParentSize>
                        {x.children}
                    </FitParentSize>
                ),
                forceRender: x.forceRender
            }))}
            {...rest}
        />
    );
}

function DockCard({children, ...props}: CardProps) {
    return (
        <Card
            {...props}
            headStyle={{
                overflow: "auto"
            }}
            style={{
                borderRadius: "4px",

                height: "100%",
                maxHeight: "100%",

                display: "flex",
                flexDirection: "column",

                boxShadow: "0px 8px 10px 0px rgba(208, 216, 243, 0.6)",
                ...props.style
            }}
            bodyStyle={{
                padding: "12px",
                flexGrow: "1",
                overflow: "hidden",
            }}
        >
            {children}
        </Card>
    );
}
