import React from "react";
import {Button, message, Popconfirm, Space, Tree} from "antd";
import {API_URL} from "../constants";
import axios from "axios";
import {authHeader} from "../Utilities";
import ImageViewer from "./ImageViewer/ImageViewer";
import WorkspaceViewModeService from "../services/WorkspaceViewModeService";
import CacheService from "../services/CacheService";
import {withRouter} from "react-router";
import {Buffer} from "buffer/";

import AllResultsService from "../services/AllResultsService";
const { TreeNode } = Tree;

class ViewDocumentInner extends React.Component {
  constructor(props) {
    super(props);
    this.viewerRef = React.createRef();
    this.state = {
      currentUser: null,
      finalResultId: null,
      canCopyToFinalResults: false,
      commentsAllowed: false
    };
  }

  loadFinalResult = (setStateCallback) => {
    return axios.get(
      API_URL + `/projects/${this.props.match.params.runId}/processing_runs/${this.props.match.params.runId}/results/${this.props.match.params.documentId}/final_result`,
    {headers: authHeader()}
    ).then(res => {
      this.setState({finalResultId: res.data.final_result_id}, setStateCallback);
    });
  };



  componentDidMount() {
    this.loadUser();
    this.loadFinalResult();
  }

  loadUser = () => {
    axios.get(API_URL + "/get_current_user", {headers: authHeader()}).then(res => {
      const allPermissions = [...res.data.permissions, ...res.data.organization.permissions];
      CacheService.getProjectData(this.props.match.params.projectId).then(projectData => {
        if (allPermissions.filter(perm => perm.permission_type === "finalize_results" && perm.target === projectData.workspace_id).length > 0) {
          this.setState({canCopyToFinalResults: true});
        }

        if (allPermissions.filter(perm => perm.permission_type === "hierarchies" && perm.target === projectData.workspace_id).length > 0) {
          this.setState({hierarchiesAllowed: true});
        }

        if (allPermissions.filter(perm => perm.permission_type === "comments" && perm.target === projectData.workspace_id).length > 0) {
          this.setState({commentsAllowed: true});
        }
      });
      this.setState({currentUser: res.data});
    }).catch(err => {
    });
  };

  handleCreateSample = () => {
    axios.post(API_URL + `/projects/${this.props.match.params.projectId}/samples`, {
      "result_id": this.props.match.params.documentId
    }, {headers: authHeader()}).then(result => {
      message.success("Sample created");
    }).catch(err => {
      message.error("Failed to create sample");
    });
  };

  handleCreateSymbolsSample = () => {
    axios.post(API_URL + `/projects/${this.props.match.params.projectId}/object_detection_samples`, {
      "result_id": this.props.match.params.documentId,
      "selected_labels": this.viewerRef.current.state.selectionParams,
    }, {headers: authHeader()}).then(result => {
      message.success("Symbols sample created");
    }).catch(err => {
      message.error("Failed to create symbols sample");
    });
  };

  handleCreateTextRule = () => {
    const curTag = this.viewerRef.current.state.selectedObject;
    if (!curTag) return;
    const curTagNumber = curTag.objectMetadata.text;
    axios.post(API_URL + `/projects/${this.props.match.params.projectId}/get_tag_regexp`, {
      "tag_number": curTagNumber
    }, {headers: authHeader()}).then(result => {
      const regexpBase64 = Buffer.from(result.data.regexp).toString("base64");
      this.props.history.push(`/project/${this.props.match.params.projectId}/extract_tags/new_text_rule/${regexpBase64}`);
    }).catch(err => {
      console.log(err);
    });
  };

  handleCopyToFinalResults = () => {
    axios.post(
      API_URL + `/projects/${this.props.match.params.projectId}/processing_runs/${this.props.match.params.runId}/results/${this.props.match.params.documentId}/copy_to_final_results`,
      {},
      {headers: authHeader()}
    ).then(result => {
      message.success("Copied");
      this.loadFinalResult(_ => delete CacheService.sourceRunsCache[this.state.finalResultId]);
    }).catch(err => {
      message.error("Failed to copy to final results");
    });
  };

  handleOpenInResults = () => {
    this.props.history.push(`/project/${this.props.match.params.projectId}/final_results/view_result/${this.state.finalResultId}`);
  };

  loadHierarchyNodeAttributes = (hierarchyId) => {
    return AllResultsService.getHierarchyNodeAttributes(hierarchyId, {
      result_id: this.props.match.params.documentId,
      is_final: false
    });
  };

  render() {
    const showExtraFeatures = this.state.currentUser && (
        (this.state.currentUser.roles.includes("admin") || this.state.currentUser.roles.includes("analyst"))
          && !(this.state.currentUser.roles.includes("admin") && WorkspaceViewModeService.isInViewingMode())
    );
    // const backLink = showExtraFeatures ? `/project/${this.props.match.params.projectId}/explore_results/${this.props.match.params.runId}` : '/';
    return (
    <div style={{display: "flex", flexDirection: "column", width: "100%"}}>{/* DO NOT CHANGE THIS LINE*/}
      {/*<Row style={{marginBottom: '16px', flexGrow: 0}}>*/}
      {/*  <Col span={19} offset={5} style={{display: 'flex'}}>*/}

      {/*    <Link to={backLink}>*/}
      {/*      <Button shape="circle" size="medium" icon={<ArrowLeftOutlined />} style={{marginRight: '16px'}}/>*/}
      {/*    </Link>*/}
      {/*    {showExtraFeatures &&*/}
      {/*      <Button style={{marginLeft: 'auto', marginRight: '16px'}} onClick={this.handleCreateTextRule}>Create text rule</Button>*/}
      {/*    }*/}

      {/*    {showExtraFeatures &&*/}
      {/*    <Button style={{marginLeft: 'auto', marginRight: '16px'}} onClick={this.handleCreateSymbolsSample}>Create*/}
      {/*      symbols sample</Button>*/}
      {/*    }*/}
      {/*    {*/}
      {/*      this.state.finalResultId &&*/}
      {/*      <Popconfirm title={(<div>*/}
      {/*        The document is already present in final results.*/}
      {/*        <br />*/}
      {/*        The existing copy will be lost.*/}
      {/*        <br />*/}
      {/*        <br />*/}
      {/*        Are you sure to overwrite it?*/}
      {/*      </div>)*/}
      {/*      } onConfirm={this.handleCopyToFinalResults} okText="Yes" cancelText="No">*/}
      {/*        <Button style={{marginRight: '16px', marginLeft: showExtraFeatures ? 'auto' : 'initial'}} >Copy to final results</Button>*/}
      {/*      </Popconfirm>*/}
      {/*    }*/}

      {/*    {*/}
      {/*      !this.state.finalResultId &&*/}
      {/*      <Button*/}
      {/*        style={{marginRight: '16px', marginLeft: showExtraFeatures ? 'auto' : 'initial'}}*/}
      {/*        onClick={this.handleCopyToFinalResults}>Copy to final results*/}
      {/*      </Button>*/}
      {/*    }*/}

      {/*    {this.state.finalResultId && <Button onClick={this.handleOpenInResults} style={{marginRight: '16px'}}>Open in results</Button>}*/}

      {/*    {showExtraFeatures &&*/}
      {/*      <Button onClick={this.handleCreateSample}>Create sample</Button>*/}
      {/*    }*/}

      {/*  </Col>*/}
      {/*</Row>*/}
      <ImageViewer
          showThumbnailsDock={true}
          editable={false}
          loadHierarchyNodeAttributes={this.loadHierarchyNodeAttributes}
          hierarchiesAllowed={this.state.hierarchiesAllowed}
          commentsAllowed={this.state.commentsAllowed}
          history={this.props.history}
          ref={this.viewerRef}
          location={this.props.location}
          projectId={this.props.match.params.projectId}
          parentProps={this.props}
          topPanelChildren={
            (
              <React.Fragment>
                <Space size="middle">
                  {showExtraFeatures &&
                    <Button onClick={this.handleCreateTextRule}>Create text rule</Button>
                  }

                  {showExtraFeatures &&
                    <Button onClick={this.handleCreateSymbolsSample}>Create
                    symbols sample</Button>
                  }
                  {this.state.canCopyToFinalResults && (
                      <>
                        {
                          this.state.finalResultId &&
                          <Popconfirm title={(<div>
                            The document is already present in final results.
                            <br/>
                            The existing copy will be lost.
                            <br/>
                            <br/>
                            Are you sure to overwrite it?
                          </div>)
                          } onConfirm={this.handleCopyToFinalResults} okText="Yes" cancelText="No">
                            <Button>Copy to final results</Button>
                          </Popconfirm>
                        }

                        {
                          !this.state.finalResultId &&
                          <Button
                              onClick={this.handleCopyToFinalResults}>Copy to final results
                          </Button>
                        }
                    </>
                  )
                  }

                  {this.state.finalResultId && <Button onClick={this.handleOpenInResults}>Open in results</Button>}

                  {showExtraFeatures &&
                    <Button onClick={this.handleCreateSample}>Create sample</Button>
                  }
                </Space>
              </React.Fragment>
            )
          }
          loadAnnotation={() => {
            return axios.get(
                API_URL + `/projects/${this.props.match.params.projectId}/processing_runs/${this.props.match.params.runId}/results/${this.props.match.params.documentId}`,
                {headers: authHeader()}
            );
          }}
      />
      </div>
    );
  }
}


const ViewDocument = withRouter(({history, ...props}) =>
    <ViewDocumentInner {...props} history={history} key={props.match.params.documentId}/>);
export {ViewDocument};
