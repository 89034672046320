import React from "react";
import {HierarchiesList} from "./Hierarchies/HierarchiesList";
import {ViewHierarchy} from "./Hierarchies/ViewHierarchy";

export class HierarchiesView extends React.Component {
  state = {
    mode: "list",
    currentHierarchyId: null,
  };

  reloadView = () => {
    const id = this.state.currentHierarchyId;
    this.backToList();
    this.handleView(id);
  };

  backToList = () => {
    this.setState({mode: "list", currentHierarchyId: null});
  };

  handleView = hierarchyId => {
    this.setState({
      mode: "view",
      currentHierarchyId: hierarchyId,
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.currentHierarchyId !== this.state.currentHierarchyId) {
      this.props.imageViewer.viewHierarchyStateChanged$.next({
        currentHierarchyId: this.state.currentHierarchyId
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        { this.state.mode === "list" && <HierarchiesList onView={this.handleView} /> }
        { this.state.mode === "view" &&
          <ViewHierarchy
            imageViewer={this.props.imageViewer}
            hierarchyId={this.state.currentHierarchyId}
            onBack={this.backToList}
            onSoftReload={this.reloadView}
          />
        }
      </React.Fragment>
    );
  }
}