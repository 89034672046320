import React, {useContext, useState} from "react";
import {Layout, Menu} from "antd";
import KHHeader from "../KHHeader";
import {Link} from "react-router-dom";
import {AnimatedAuthUser} from "../../Utilities";
import {AuthContext} from "../../contexts/AuthContext";
import {ViewWorkspaceSwitch} from "../Misc/ViewWorkspaceSelect";
import {
    AuditOutlined,
    CommentOutlined,
    FileSearchOutlined,
    SearchOutlined,
    SettingOutlined,
    TableOutlined,
    UsergroupAddOutlined,
    UserOutlined
} from "@ant-design/icons";


function AdvancedSubMenu(props) {
    const authContext = useContext(AuthContext);
    const user = new AnimatedAuthUser(authContext.user);

    if (!user.canModifyCurrentWorkspaceReports()) return null;

    return (
        <Menu.SubMenu title="Advanced" {...props} icon={<SettingOutlined/>}>
            <Menu.Item key="configure_reports" {...props}>
                <Link to={"/user_view/configure_reports"}>
                    Reports
                </Link>
            </Menu.Item>
        </Menu.SubMenu>
    );
}


function CommentsMenuItem(props) {
    const authContext = useContext(AuthContext);
    const user = new AnimatedAuthUser(authContext.user);

    if (!user.canSearchForComments()) return null;

    return (
        <Menu.Item key="comments" {...props} icon={<CommentOutlined/>}>
            <Link to={"/user_view/comments"}>
                Comments
            </Link>
        </Menu.Item>
    );
}

export class UserViewLayout extends React.Component {
    static contextType = AuthContext;

    render() {
        let selectedMenuItem;
        switch (this.props.location.pathname.split("/")[2]) {
            case "search":
                selectedMenuItem = "search";
                break;
            case "explore_results":
                selectedMenuItem = "explore_results";
                break;
            case "finalize_results":
                selectedMenuItem = "finalize_results";
                break;
            case "users":
                selectedMenuItem = "users";
                break;
            case "organizations":
                selectedMenuItem = "organizations";
                break;
            case "configure_reports":
                selectedMenuItem = "configure_reports";
                break;
            case "comments":
                selectedMenuItem = "comments";
                break;
            case "document_registry":
                selectedMenuItem = "document_registry";
                break;
            default:
                selectedMenuItem = "search";
        }

        const showExtraFeatures = this.context.user && this.context.user.roles.includes("manager");

        return (
            <Layout style={{height: "100vh"}}>
                <KHHeader/>
                <Layout>
                    <Sider width={"20%"} storageKey={"kh-user-view-sider"}>
                        {collapsed => (
                            <>
                                <Menu id="main-menu" theme="light" mode="inline" selectedKeys={[selectedMenuItem]}>
                                    <Menu.Item key="search" icon={<SearchOutlined/>}>
                                        <Link to={"/user_view/search"}>
                                            Search
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="explore_results" icon={<FileSearchOutlined/>}>
                                        <Link to={"/user_view/explore_results"}>
                                            Explore results
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="finalize_results" icon={<AuditOutlined/>}>
                                        <Link to={"/user_view/finalize_results"}>
                                            Finalized results
                                        </Link>
                                    </Menu.Item>
                                    <CommentsMenuItem key={"comments"}/>
                                    {showExtraFeatures &&
                                        <Menu.Item key="users" icon={<UserOutlined/>}>
                                            <Link to={"/user_view/users"}>
                                                Users
                                            </Link>
                                        </Menu.Item>
                                    }
                                    {showExtraFeatures &&
                                        <Menu.Item key="organizations" icon={<UsergroupAddOutlined/>}>
                                            <Link to={"/user_view/organizations"}>
                                                Organizations
                                            </Link>
                                        </Menu.Item>
                                    }
                                    <Menu.Item key="document_registry" icon={<TableOutlined/>}>
                                        <Link to={"/user_view/document_registry"}>
                                            Document Register
                                        </Link>
                                    </Menu.Item>
                                    <AdvancedSubMenu/>
                                </Menu>
                                <div
                                    style={{
                                        position: "absolute", bottom: 0, width: "100%", marginBottom: "20%",
                                        display: collapsed ? "none" : "initial"
                                    }}
                                >
                                    <ViewWorkspaceSwitch
                                        onChange={newId => {
                                            this.props.history.replace({
                                                pathname: "/",
                                                search: ""
                                            });
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </Sider>

                    <Layout.Content style={{width: "75%", padding: "24px"}}>
                        {this.props.children}
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }
}

function Sider({storageKey, children, ...props}) {
    const [collapsed, setCollapsed] = useState(localStorage.getItem(storageKey) === "true");

    const handleCollapse = value => {
        setCollapsed(value);
        localStorage.setItem(storageKey, value);
    };

    return (
        <Layout.Sider
            collapsible
            theme={"light"}
            collapsed={collapsed}
            {...props}
            onCollapse={handleCollapse}
        >
          {children(collapsed)}
        </Layout.Sider>
    );
}