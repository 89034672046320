import {AsyncDataSource, InMemoryAsyncDataSource} from "../../Misc/Table/AsyncDataSourceGeneric";
import HierarchiesService from "../../../services/HierarchiesService";
import ImageViewerObject, {ImageViewerObjectAttribute} from "../ImageViewerObject";
import HierarchyNode, {ObjectReferenceWithExtraData, ReferencesDelegate} from "./HierarchyNode";
import {memoized} from "../../../UtilitiesTs";
import {ResultReference} from "../../../services/ApiModels/ResultReference";

export class ReferencesV1 implements ReferencesDelegate {
    private readonly _node: HierarchyNode;

    constructor(node: HierarchyNode) {
        this._node = node;
    }

    getRefsWithExtraDataSource(skipOnSameResult: boolean): AsyncDataSource<ObjectReferenceWithExtraData> {
        const ivResult = this._node.hierarchy.imageViewer.toResultReference();
        const isOnSameResult = (result: ResultReference) => {
            return result.result_id === ivResult.result_id && result.is_final === ivResult.is_final;
        };
        const node = this._node;
        const hierarchy = this._node.hierarchy;
        const fetchAll = () => HierarchiesService.fetchNodeReferencesExtraData(
            hierarchy, node
        ).then(response => {
            const refIds = node.references
                .map(ref => ref.id);
            return response.data
                .filter(desc => desc.tag_reference != null)
                .filter(desc => skipOnSameResult ? !isOnSameResult(desc.tag_reference!.result_reference) : true)
                .filter(desc => refIds.includes(desc.id))
                .map(desc => {
                    const reference = node.references.find(
                        ref => ref.id === desc.id
                    )!;
                    return {
                        ...desc,
                        tag_reference: desc.tag_reference!,
                        reference: reference
                    };
                });
        });
        return new InMemoryAsyncDataSource<ObjectReferenceWithExtraData>(memoized(fetchAll));
    }

    async getObjectAttributes(): Promise<ImageViewerObjectAttribute[] | null> {
        const node = this._node;
        if (node.references.length > 0) {
            return node.references[0].getObjectAttributes();
        } else {
            return null;
        }
    }

    findPotentialObject(): ImageViewerObject | null {
        const node = this._node;
        return (
            node.hierarchy.imageViewer.state.allObjects ?? []
        ).find(obj => obj.text === node.text && obj.label === node.label) ?? null;
    }

    findReferencedObject(): ImageViewerObject | null {
        const node = this._node;
        const objectsCandidates = (
            node.hierarchy.imageViewer.state.allObjects ?? []
        ).filter(obj => obj.text === node.text && obj.label === node.label);
        const matchedObject = objectsCandidates.find(obj => this.matchesObject(obj)) ?? null;
        return matchedObject;
    }

    matchesObject(obj: ImageViewerObject): boolean {
        return this._node.references.some(ref => ref.matchesObject(obj));
    }

    hasOnPageReference(): boolean {
        const result = this.findReferencedObject() != null;
        return result;
    }

    isVirtual(): boolean {
        return this._node.references.length === 0;
    }
}