import axios from "axios";

interface NotAborted<T> {
    val(): T;
}

export interface MayBeAborted<T> {
    isCompleted(): this is NotAborted<T>;
}

export function fetchAbortable<T>(promise: () => Promise<T>): Promise<MayBeAborted<T>> {
    return promise().then(res => {
        return new class implements MayBeAborted<T>, NotAborted<T> {
            isCompleted(): this is NotAborted<T> {
                return true;
            }

            val(): T {
                return res;
            }
        }();
    }).catch(err => {
        if (axios.isCancel(err)) {
            return new class implements MayBeAborted<T> {
                isCompleted(): this is NotAborted<T> {
                    return false;
                }
            }();
        }
        throw err;
    });
}
