import Cookies from "universal-cookie";
import {VIEW_WORKSPACE_ID_COOKIE_NAME} from "../constants";


class WorkspaceViewModeService {
  constructor() {
    this._cookies = new Cookies();
  }

  addChangeListener(callback) {
    const changeListener = (args) => {
      if (args.name === VIEW_WORKSPACE_ID_COOKIE_NAME) {
        callback(args.value);
      }
    };

    this._cookies.addChangeListener(changeListener);

    return {
      release: () => {
        this._cookies.removeChangeListener(changeListener);
      }
    };
  }

  enterWorkspace = (workspaceId) => {
    this._cookies.set(VIEW_WORKSPACE_ID_COOKIE_NAME, workspaceId, { path: "/" });
  };

  exitWorkspace = () => {
    this._cookies.remove(VIEW_WORKSPACE_ID_COOKIE_NAME, { path: "/" });
  };

  getCurrentWorkspaceId = () => {
    const currentWorkspaceId = this._cookies.get(VIEW_WORKSPACE_ID_COOKIE_NAME);
    return currentWorkspaceId;
  };

  isInViewingMode = () => {
    return !!this.getCurrentWorkspaceId();
  };
}

export default new WorkspaceViewModeService();