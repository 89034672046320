import React, {useEffect, useState} from "react";
import {Button, Divider, Popover, Radio, Row, Slider, Space, Spin, Tooltip} from "antd";

import {AutoSizer, List} from "react-virtualized";
import axios from "axios";
import {authHeader, cache} from "../../../Utilities";
import {API_URL} from "../../../constants";
import {AppstoreOutlined, CommentOutlined, ControlOutlined, RightOutlined} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import {Comments} from "../Comments/Components/Comments";


function FakeImg({color, spinning}) {
    return (
        <Spin spinning={spinning}>
            <div
                style={{
                    display: "block",
                    width: "50px",
                    height: "50px",
                }}
            />
        </Spin>
    );
}


function ThumbnailImg({src, pageIdx}) {
    const popoverContent = (
        <img
            src={src}
            alt={pageIdx}
            style={{maxHeight: "90vh", maxWidth: "100%", height: "auto"}}
        />
    );

    return (
        <Tooltip
            placement="right" title={popoverContent} color="white"
            mouseEnterDelay={0.5}
            overlayStyle={{maxHeight: "100vh", maxWidth: "100vh"}}
            destroyTooltipOnHide={true}
        >
            <img
                src={src}
                alt={pageIdx}
                style={{maxHeight: "100%", maxWidth: "100%"}}
            />
        </Tooltip>
    );
}


export function Thumbnail({pageIndex, currentPage, source, total, onClick}) {
    const [item, setItem] = useState({
        img: <FakeImg color={"#AAA"} spinning={true}/>
    });

    useEffect(() => {
        let cancelled = false;

        const load = () => {
            source.get(pageIndex)
                .then(data => {
                    if (!cancelled) {
                        setItem(data);
                    }
                })
                .catch(err => {});
        };

        let timer;
        if (source.prepared != null && source.prepared(pageIndex)) {
            load();
        } else {
            timer = setTimeout(load, 1000);
        }

        return () => {
            cancelled = true;
            if (timer != null) {
                clearTimeout(timer);
            }
        };
    }, [pageIndex, source]);

    const pageText = () => {
        let text = `Page ${pageIndex + 1}/${total}`;

        if (currentPage === pageIndex) {
            text = (
              <Text strong underline>{text}</Text>
            );
        }

        return text;
    };

    const cursorStyle = () => {
        let result;

        if (item.ref == null) {
            result = {};
        } else {
            result = {cursor: "pointer"};
        }

        return result;
    };

    return (
        <div
            style={{
                padding: "8px 16px",
                borderBottom: "1px solid #DDD",
                height: "100%",
                width: "100%",
                textAlign: "center",
                ...(cursorStyle())
            }}
            onClick={e => {
                if (item.ref != null) {
                    onClick(item.ref);
                }
            }}
        >
            {item.img}
            {pageText()}
        </div>
    );
}


export function VirtualThumbnails({total, thumbnailsSource, currentPage, onClick}) {
    const [source, setSource] = useState(thumbnailsSource());

    return (
        <div style={{height: "100%", width: "100%"}}>
            <AutoSizer>
                {({width, height}) => {
                    const itemHeight = 150;
                    const itemWidth = 200;

                    const itemsPerRow = Math.max(Math.floor(width / itemWidth), 1);
                    const rowCount = Math.ceil(total / itemsPerRow);
                    const scrollToIndex = Math.floor(currentPage / itemsPerRow);

                    return (
                        <List
                            width={width}
                            height={height}
                            rowCount={rowCount}
                            rowHeight={itemHeight}
                            scrollToIndex={scrollToIndex}
                            overscanRowCount={3}
                            rowRenderer={({index, key, style}) => {
                                const items = [];
                                const fromIndex = index * itemsPerRow;
                                const toIndex = Math.min(fromIndex + itemsPerRow, total);

                                for (let i = fromIndex; i < toIndex; i++) {
                                    items.push(
                                        <div
                                            key={i}
                                            style={{
                                                width: itemWidth,
                                                height: itemHeight,
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: "0 0.5rem",
                                                boxSizing: "border-box",
                                            }}
                                        >
                                            <Thumbnail
                                                source={source}
                                                pageIndex={i}
                                                currentPage={currentPage}
                                                total={total}
                                                onClick={onClick}
                                            />
                                        </div>
                                    );
                                }

                                return (
                                    <div
                                        key={key}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "inline-flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            ...style
                                        }}
                                    >
                                        {items}
                                    </div>
                                );
                            }}
                        />
                    );
                }}
            </AutoSizer>
        </div>
    );
}


export function ThumbnailsDock({children, onChangeTab, dockTab, commentsAllowed, onHide}) {
    const dockStyle = {
        position: "relative",
        background: "white",
        width: "100%",
        right: 0,
        bottom: 0,
        height: "85%"
    };

    return (
        <>
            <div style={{
                paddingBottom: "8px",
                borderBottom: "1px solid #DDD",
            }}>
                <Row style={{justifyContent: "flex-end"}}>
                    <Space>
                        <Button
                            id="hide-thumbnails-dock"
                            size="small"
                            onClick={e => onHide()}
                            icon={<RightOutlined />}
                        />
                    </Space>
                </Row>
            </div>
            <div style={dockStyle}>
                {children}
            </div>
            <div style={{height: "5%", width: "100%"}}>
                <Row justify={"center"}>
                    <Divider style={{marginBottom: "8px"}}/>
                    <Radio.Group
                        buttonStyle="solid"
                        size="small"
                        value={dockTab}
                        onChange={e => onChangeTab(e.target.value)}
                    >
                        <Radio.Button value={"thumbnails"}>
                            <AppstoreOutlined />
                        </Radio.Button>
                        {commentsAllowed && (
                            <Radio.Button value={"comments"}>
                                <CommentOutlined id="comments-tab" />
                            </Radio.Button>
                        )}
                    </Radio.Group>
                </Row>
            </div>
        </>
    );
}


export class BatchedThumbnails {
    constructor(projectId, resultId, isFinal, total) {
        this._projectId = projectId;
        this._resultId = resultId;
        this._isFinal = isFinal;
        this._total = total;
    }

    async get(pageIndex, batchSize) {
        const res = await axios.get(API_URL + `/projects/${this._projectId}/get_page_thumbnail_batched`,
            {
                params: {
                    page_number: pageIndex,
                    batch_size: batchSize,
                    total: this._total,
                    result_id: this._resultId,
                    is_final: this._isFinal,
                },
                headers: authHeader()
            }
        );

        return res.data;
    }
}


export class BatchedCachedSource {
    constructor(batchedSource, batchSize) {
        this._batchedSource = batchedSource;
        this._batchSize = batchSize;
        this._cache = {};
    }

    prepared(pageIndex) {
        const key = Math.floor(pageIndex / this._batchSize);
        return this._cache[key] != null;
    }

    async get(pageIndex) {
        const key = Math.floor(pageIndex / this._batchSize);
        if (this._cache[key] == null) {
            this._cache[key] = cache(() => this._batchedSource.get(pageIndex, this._batchSize));
        }

        const data = await this._cache[key]();
        const el = data.find(el => el.page_index === pageIndex);
        const curSrc = `data:application/octet-stream;base64,${el.img}`;

        return {
            img: (
                <ThumbnailImg src={curSrc} pageIdx={pageIndex}/>
            ),
            ref: el.ref
        };
    }
}


export function Thumbnails(
    {currentPage, total, thumbnailsSource, onClick, imageViewer, commentsAllowed,
        newCommentSubject, zoomToCommentMarker, resetSelectedObject, onHide}
) {
    const [currentTab, setCurrentTab] = useState("thumbnails");

    useEffect(() => {
        const sub = imageViewer.commentSourceSelected$.subscribe(e => {
            if (e.target != null) {
                setCurrentTab("comments");
            }
        });

        return () => {
            sub.unsubscribe();
        };
    }, [imageViewer]);

    return (
        <>
            <ThumbnailsDock
                commentsAllowed={commentsAllowed}
                onHide={onHide}
                dockTab={currentTab}
                onChangeTab={setCurrentTab}
            >
                <div style={{display: currentTab === "thumbnails" ? "initial" : "none"}}>
                    <VirtualThumbnails
                        onClick={onClick}
                        currentPage={currentPage}
                        total={total}
                        thumbnailsSource={thumbnailsSource}
                    />
                </div>
                {commentsAllowed && (
                    <div style={{display: currentTab === "comments" ? "initial" : "none"}}>
                        <Comments
                            imageViewer={imageViewer}
                            resetSelectedObject={resetSelectedObject}
                            newCommentSubject={newCommentSubject}
                            zoomToCommentMarker={zoomToCommentMarker}
                        />
                    </div>
                )}
            </ThumbnailsDock>
        </>
    );
}
