import React, {useState} from "react";
import {Button, Dropdown, Menu, message, Popconfirm, Space} from "antd";
import {BulkUpdateHierarchyModal} from "./BulkUpdateHierarchy";
import {DownOutlined} from "@ant-design/icons";
import Hierarchy from "../../ImageViewerHandlers/Hierarchy/Hierarchy";
import {ViewHierarchy} from "./ViewHierarchy";

export function ViewHierarchyActions({hierarchy, viewHierarchy}: {
    hierarchy?: Hierarchy,
    viewHierarchy: ViewHierarchy,
}) {
    const [bulkUpdateModalVisible, setBulkUpdateModalVisible] = useState(false);

    const actionsMenu = (
        <Menu>
        {hierarchy != null && (
            <Menu.Item key="toggle-hierarchy">
                <Popconfirm
                    title={hierarchy.hidden ? "Are you sure to unhide hierarchy?" : "Are you sure to hide hierarchy?"}
                    onConfirm={() =>
                        hierarchy
                            .setHiddenStatus(!hierarchy.hidden)
                            .then(() => {
                                viewHierarchy.softReload();
                                message.success("Success");
                                viewHierarchy.props.imageViewer.hierarchyListChanged$.next();
                            })
                            .catch(() => message.error("Failed to switch"))
                    }
                >
                    <div style={{ width: "100%" }}>
                        <Space>
                            {hierarchy.hidden ? "Unhide" : "Hide"}
                        </Space>
                    </div>
                </Popconfirm>
            </Menu.Item>
        )}
            <Menu.Item onClick={() => setBulkUpdateModalVisible(true)}>
                Bulk Update
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Dropdown overlay={actionsMenu} trigger={["click"]}>
                <Button>Actions <DownOutlined/></Button>
            </Dropdown>
            {hierarchy != null && (
              <>
                  <BulkUpdateHierarchyModal
                      onOk={() => viewHierarchy.reloadHierarchy()}
                      hierarchy={hierarchy}
                      visible={bulkUpdateModalVisible}
                      setVisible={setBulkUpdateModalVisible}
                  />
              </>
            )}
        </>
    );
}
