import React, {useCallback, useState} from "react";
import {DataEditorProps, Rectangle} from "@glideapps/glide-data-grid";
import {Button, Checkbox, Dropdown, Input, Row} from "antd";
import {DeleteOutlined, SearchOutlined, SortAscendingOutlined, SortDescendingOutlined} from "@ant-design/icons";
import {ItemType} from "antd/lib/menu/hooks/useItems";

export type HeaderMenuProps = {
    itemsOf: (col: number, close: () => void) => ItemType[],
}

export function useHeaderMenu({itemsOf}: HeaderMenuProps) {
    const [bounds, setBounds] = useState<Rectangle>();
    const [isOpen, setIsOpen] = useState(false);
    const [col, setCol] = useState(-1);

    const onHeaderMenuClick = useCallback<NonNullable<DataEditorProps["onHeaderMenuClick"]>>((col, bounds) => {
        setBounds(bounds);
        setCol(col);
        setIsOpen(false);
        setTimeout(() => {
            setIsOpen(true);
        });
    }, []);

    const close = () => {
        setIsOpen(false);
        setCol(-1);
    };

    const menu = (
        <Dropdown
            menu={{
                items: col !== -1 ? itemsOf(col, close) : [],
            }}
            trigger={["hover", "click"]}
            open={isOpen}
            onOpenChange={open => {
                if (!isOpen) return;
                setIsOpen(open);
            }}
        >
            <div
                style={{
                    position: "fixed",
                    bottom: (bounds?.y ?? 0) + (bounds?.height ?? 0),
                    height: bounds?.height ?? 0,
                    left: bounds?.x ?? 0,
                    right: (bounds?.x ?? 0) + (bounds?.width ?? 0),
                    top: bounds?.y ?? 0,
                    width: bounds?.width ?? 0,
                }}
            />
        </Dropdown>
    );

    return {
        onHeaderMenuClick,
        headerMenu: menu
    };
}

export function ColumnContextItemDivider(): ItemType  {
    return {
        type: "divider"
    };
}

type OnDeleteSource = {
    onDelete(): void
}

export function ColumnContextItemDelete(target: OnDeleteSource) {
    return {
        label: "Delete",
        danger: true,
        onClick: () => target.onDelete(),
        key: "delete",
        icon: <DeleteOutlined/>,
    };
}

type OnSortSource = {
    onSort(order: 1 | -1): void
}

export function ColumnContextItemSortAsc(target: OnSortSource) {
    return {
        label: "Sort ascending",
        key: "sort-asc",
        onClick: () => target.onSort(1),
        icon: <SortAscendingOutlined/>,
    };
}

export function ColumnContextItemSortDesc(target: OnSortSource) {
    return {
        label: "Sort descending",
        onClick: () => target.onSort(-1),
        key: "sort-desc",
        icon: <SortDescendingOutlined/>,
    };
}

type OnFilterSource = {
    onFilter(value: string): void
}

type FilterInputType = "text" | "number";

export function ColumnContextItemFilter(target: OnFilterSource, type: FilterInputType = "text") {
    return {
        label: (
            <FilterInput onSubmit={value => target.onFilter(value)} type={type}/>
        ),
        key: "filter",
        icon: <SearchOutlined/>,
    };
}

type FilterInputProps = {
    onSubmit: (value: string) => void,
    type: FilterInputType,
}

function FilterInput({onSubmit, type}: FilterInputProps) {
    const [value, setValue] = useState("");

    const submit = () => {
        onSubmit(value);
        setValue("");
    };

    return (
        <Input
            type={type}
            value={value}
            onChange={e => setValue(e.target.value)}
            size={"small"}
            onKeyPress={e => {
                if (e.key === "Enter") {
                    submit();
                }
            }}
        />
    );
}

type OnBoolFilterSource = {
    onFilter(value: boolean): void
}

export function ColumnContextItemBoolFilter(label: string, target: OnBoolFilterSource) {
    return {
        label: (
            <BoolFilterInput label={label} onSubmit={value => target.onFilter(value)}/>
        ),
        key: "filter",
        icon: <SearchOutlined/>,
    };
}

type BoolFilterInputProps = {
    label: string,
    onSubmit: (value: boolean) => void,
}

function BoolFilterInput({label, onSubmit}: BoolFilterInputProps) {
    const [value, setValue] = useState(false);

    const submit = () => {
        onSubmit(value);
        setValue(false);
    };

    return (
        <Row justify={"space-between"}>
            <Row>
                <span style={{marginRight: "8px"}}>{label}:</span>
                <Checkbox
                    checked={value}
                    onChange={e => setValue(e.target.checked)}
                />
            </Row>
            <Button
                size={"small"}
                onClick={submit}
            >
                Search
            </Button>
        </Row>
    );
}
