import Rectangle from "./Geometry/Rectangle";
import {fabric} from "fabric";
import {StringWithAutocomplete} from "../../UtilitiesTs";

type Bbox = {
    x1: number,
    y1: number,
    x2: number,
    y2: number,
}

type MetadataAttribute = {
    key: string,
    value: string
}

type Metadata = {
    description: string,
    attributes: MetadataAttribute[]
}

export type AttributesSource = {
    label: string,
    text: string,
    metadata: Metadata
}

export type ImageViewerObjectAttribute = {
    key: StringWithAutocomplete<"Class" | "Text" | "Description">,
    value: string
}

export function getAttributes(source: AttributesSource): ImageViewerObjectAttribute[] {
    const result: ImageViewerObjectAttribute[] = [
        {key: "Class", value: source.label},
        {key: "Text", value: source.text},
        {key: "Description", value: source.metadata.description},
        ...source.metadata.attributes
    ];
    return result;
}

export function getAttributesForNode(source: AttributesSource): MetadataAttribute[] {
    const prefix = "_hierarchy_";
    const result: MetadataAttribute[] = [];
    source.metadata.attributes.forEach(x => {
        if (x.key.startsWith(prefix)) {
            result.push({
                key: x.key.substring(prefix.length),
                value: x.value
            });
        }
    });
    return result;
}

export function getTagClassValue(source: {objectMetadata: AttributesSource}): string;
export function getTagClassValue(source: AttributesSource): string;
export function getTagClassValue(src: any): string {
    const source = toAttributesSource(src);
    const attr = source.metadata.attributes.find(a => a.key === "tag_class");
    return attr?.value ?? source.label;
}

export function toAttributesSource(source: {objectMetadata: AttributesSource}): AttributesSource;
export function toAttributesSource(source: AttributesSource): AttributesSource;
export function toAttributesSource(src: any): AttributesSource {
    let source: AttributesSource;
    if (src.objectMetadata != null) {
        source = src.objectMetadata;
    } else {
        source = src;
    }
    return source;
}

export default class ImageViewerObject {
    public id: number;
    public label: string;
    public text: string;
    public bbox: Bbox;
    public metadata: Metadata;
    public canvasObject: fabric.Object;
    public shape?: any;

    constructor({id, label, text, bbox, metadata, canvasObject, shape = undefined}: {
        id: number, label: string, text: string, bbox: Bbox, metadata: Metadata, canvasObject: fabric.Object, shape: any
    }) {
        this.id = id;
        this.label = label;
        this.text = text;
        this.bbox = bbox;
        this.metadata = metadata;
        this.canvasObject = canvasObject;
        this.shape = shape;
    }

    getRect() {
        return new Rectangle(this.bbox.x1, this.bbox.y1, this.bbox.x2, this.bbox.y2);
    }

    getDict() {
        const result = {
            id: this.id,
            label: this.label,
            text: this.text,
            bbox: this.bbox,
            metadata: this.metadata,
            shape: undefined
        };
        if (this.shape) result.shape = this.shape;
        return result;
    }

    // returns null if the attribute does not exist
    getAttributeValue(attributeName: string) {
        const attr = this.metadata.attributes.find(a => a.key === attributeName);
        if (attr) return attr.value;
        return null;
    }

    getTagClassValue(): string {
        return getTagClassValue(this);
    }

    getAttributes(): ImageViewerObjectAttribute[] {
        return getAttributes(this);
    }

    isGraphObject() {
        return this.shape && this.shape.shape_type === "graph";
    }

    isValidForHierarchy() {
        return this.label.trim().length > 0 && this.text.trim().length > 0;
    }
}
