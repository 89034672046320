import {PluginAnonymous, PluginSourceSequence} from "../../../services/Plugins";
import {DocumentRegistryViewRef} from "../DocumentRegistryView";
import {Button, Checkbox, Form, Input, message, Modal, Row, Select, Space, Spin, Table, Tree} from "antd";
import {Dispatch, Key, ReactNode, SetStateAction, useContext, useEffect, useState} from "react";
import {ImmortalAutoSizer} from "../../Misc/ImmortalAutoSizer";
import {CurrentRegistry, useCurrentRegistry} from "../Resources/CurrentRegistry";
import {
    DocumentRegistry,
    ReadonlyDocumentRegistryColumns,
    useRegistryColumns,
    useRegistryPresets
} from "../../../services/DocumentRegistry/DocumentRegistry";
import {ColumnFilter, GridFilter, RegistryFilters, useRegistryFilters} from "../Resources/RegistryFilters";
import {ReadonlyResource} from "../../../services/Resources";
import {DragSortingTable} from "../../Misc/DragSortingTable";
import {FitParentSize} from "../../Misc/FitParentSize";
import SplitPane from "react-split-pane";
import {CheckOutlined, DeleteOutlined} from "@ant-design/icons";
import {useAbortControllers} from "../../../services/useAbortControllers";
import {Dep} from "../../../services/useDep";
import { FormModal } from "../../../services/FormModal";
import { AuthContext } from "../../../contexts/AuthContext";
import { AnimatedAuthUser } from "../../../Utilities";
import { ColumnPreset, DocumentRegistryPresets } from "../../../services/DocumentRegistry/DocumentRegistryPresets";

export class PluginPivotTable extends PluginSourceSequence<DocumentRegistryViewRef> {
    constructor() {
        super([
            new PluginPivotTableTree(),
        ]);
    }
}

class PluginPivotTableTree extends PluginAnonymous<DocumentRegistryViewRef> {
    constructor() {
        super("pivot-table-tree", {
            async build(target: DocumentRegistryViewRef): Promise<void> {
                const registry = target.res(CurrentRegistry);
                const filters = target.res(RegistryFilters);
                target.addDock("left-ur", {
                    children: (
                        <PivotTableDock
                            filters={filters}
                            registry={registry}
                        />
                    ),
                    key: "DOCUMENT CLASSIFICATIONS",
                    name: "Classifications",
                });
            }
        });
    }
}

type PivotTableDockProps = {
    filters: ReadonlyResource<RegistryFilters>
    registry: ReadonlyResource<CurrentRegistry>
}

function PivotTableDock({filters, registry: registryRes}: PivotTableDockProps) {
    const registry = useCurrentRegistry(registryRes);
    const registryColumns = useRegistryColumns(registry);
    const [pivotColumns, setPivotColumns] = useState<string[]>([]);

    useEffect(() => {
        setPivotColumns(pivotColumns => pivotColumns.filter(x => registryColumns.includes(x)));
    }, [registryColumns]);

    return (
        <PivotTableDockLayout
            top={(
                <PivotColumns
                    pivotColumns={pivotColumns}
                    registryColumns={registryColumns}
                    setPivotColumns={setPivotColumns}
                    registry={registry}
                />
            )}
            bottom={(
                <PivotTableTree
                    filters={filters}
                    pivotColumns={pivotColumns}
                    registry={registry}
                />
            )}
        />
    );
}

type PivotTableDockLayoutProps = {
    top: ReactNode,
    bottom: ReactNode,
}

function PivotTableDockLayout({top, bottom}: PivotTableDockLayoutProps) {
    return (
        <ImmortalAutoSizer>
            {({width, height}) => (
                <Row style={{width: width + "px", height: height + "px"}}>
                    <SplitPane
                        style={{position: "relative"}}
                        split="horizontal"
                        primary={"first"}

                        maxSize={height * 0.75}
                        minSize={height * 0.25}
                        defaultSize={height * 0.25}

                        resizerStyle={{
                            height: "8px",
                            width: "100%",
                            transform: "translate(0%, 0%)"
                        }}
                        resizerClassName={"default"}
                    >
                        <FitParentSize>
                            {top}
                        </FitParentSize>
                        <FitParentSize>
                            <div style={{position: "absolute", width: "100%", background: "#f0f2f5", height: "8px"}}/>
                            <div style={{height: "100%", width: "100%", paddingTop: "8px"}}>
                                {bottom}
                            </div>
                        </FitParentSize>
                    </SplitPane>
                </Row>
            )}
        </ImmortalAutoSizer>
    );
}

type PivotColumnsProps = {
    pivotColumns: string[],
    setPivotColumns: Dispatch<SetStateAction<string[]>>,
    registryColumns: Dep<ReadonlyDocumentRegistryColumns>,
    registry: DocumentRegistry,
}

function PivotColumns({pivotColumns, registryColumns, setPivotColumns, registry}: PivotColumnsProps) {
    const [value, setValue] = useState<string | undefined>();
    const regisryPresets = useRegistryPresets(registry);

    return (
        <FitParentSize>
            <Space style={{ width: "100%", justifyContent: "space-between", paddingBottom: "5px" }}>
                {!!pivotColumns.length &&
                    <CreatePresetAction 
                        columns={pivotColumns}
                        registryPresets={regisryPresets}
                    />
                }
                <ShowPresetsAction
                    setPivotColumns={setPivotColumns}
                    registryPresets={regisryPresets}
                />
            </Space>
            <Select
                placeholder={"Add Column"}
                value={value}
                onChange={setValue}
                onSelect={v => {
                    setPivotColumns(prev => [...prev, v]);
                    setValue("");
                }}
                showSearch={true}
                size={"small"}
                style={{width: "100%"}}
                options={registryColumns.asList().filter(x => !pivotColumns.includes(x)).map(x => ({
                    label: x,
                    value: x,
                }))}
            />
            <PivotColumnsTable pivotColumns={pivotColumns} setPivotColumns={setPivotColumns}/>
        </FitParentSize>
    );
}

type CreatePresetModalProps = {
    columns: string[],
    registryPresets: DocumentRegistryPresets,
}

function CreatePresetAction({columns, registryPresets}: CreatePresetModalProps) {
    const [isOpen, setIsOpen] = useState(false);
    const authContext = useContext(AuthContext);
    const user = new AnimatedAuthUser(authContext.user);

    const createNewPreset = async (values: any) => {
        const response = registryPresets.create(values.name, values.isGlobal, columns);
        return response.catch(()=>{message.error("Failed to create preset");});
    };


    return (
        <>
            <Button
                type="primary"
                size="small"
                onClick={() => setIsOpen(true)}
            >Save preset</Button>
            <FormModal
                formId={"create-preset-form-modal"}
                onSubmit={createNewPreset}
                open={isOpen}
                setModalOpen={setIsOpen}
                modalProps={{
                    title: "Create preset",
                    destroyOnClose: true,
                    okText: "Create",
                    width: 400
                }}
            >
                <Form.Item label="Columns">
                    {columns.join(", ")}
                </Form.Item>
                <Form.Item label="Name" name="name">
                    <Input />
                </Form.Item>
                {user.isAdmin() && 
                    <Row>
                        <Form.Item label="Is global" name="isGlobal" valuePropName={"checked"}>
                            <Checkbox />
                        </Form.Item>
                    </Row>
                }
            </FormModal>
        </>
    );
}

type ShowPresetsModalProps = {
    registryPresets: DocumentRegistryPresets
    setPivotColumns: Dispatch<SetStateAction<string[]>>
}

function ShowPresetsAction({registryPresets, setPivotColumns}: ShowPresetsModalProps) {
    const [isOpen, setIsOpen] = useState(false);
    const authContext = useContext(AuthContext);
    const user = new AnimatedAuthUser(authContext.user);

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Columns",
            dataIndex: "columns",
            key: "columns",
            render: (columns: string[]) => columns.join(", "),
        },
        {
            title: "Is global",
            dataIndex: "isGlobal",
            key: "isGlobal",
            render: (isGlobal: boolean) => (isGlobal ? "Yes" : "No"),
        },
        {
            title: "Use",
            key: "use",
            render: (_: any, record: ColumnPreset) => (
                <Button
                    type="primary"
                    size="small"
                    onClick={() => handleUse(record.columns)}
                    icon={<CheckOutlined />}
                />
            ),
        },
        {
            title: "Delete",
            key: "delete",
            render: (_: any, record: ColumnPreset) => 
                <>
                    {(user.isAdmin() || !record.isGlobal) && 
                        <Button
                            type="primary"
                            danger
                            size="small"
                            onClick={() => handleDelete(record.id)}
                            icon={<DeleteOutlined/>}
                        />
                    }
                </>
        },
    ];

    const handleUse = (columns: string[]) => {
        setPivotColumns(columns);
        setIsOpen(false);
    };

    const handleDelete = (key: any) => {
        registryPresets.delete(key).catch(() => message.error("Failed to delete preset"));
    };

    return (
        <>
            <Button
                type="primary"
                size="small"
                onClick={() => setIsOpen(true)}
            >Presets</Button>
            <Modal
                width={800}
                title="Presets list"
                open={isOpen}
                onCancel={() => setIsOpen(false)}
                destroyOnClose={true}
                footer={null}
            >
                <Table 
                    dataSource={registryPresets.asList()}
                    columns={columns}
                />
            </Modal>
        </>
    );
};

type PivotColumnsTableProps = {
    pivotColumns: string[],
    setPivotColumns: Dispatch<SetStateAction<string[]>>,
}

function PivotColumnsTable({pivotColumns, setPivotColumns}: PivotColumnsTableProps) {
    const [hoveredRowIndex, setHoveredRowIndex] = useState<number | undefined>();
    const deleteColumn = (column: string) => {
        setPivotColumns(prev => prev.filter(x => x !== column));
    };
    return (
        <DragSortingTable
            style={{width: "100%"}}
            size={"small"}
            pagination={false}
            columns={[
                {
                    title: "Column",
                    render: (_: any, value: any, index: number) => {
                        const deleteThisColumn = () => deleteColumn(value);
                        return (
                            <Row justify={"space-between"} style={{minHeight: "24px"}}>
                                {value}
                                {hoveredRowIndex === index && (
                                    <Button
                                        size={"small"}
                                        type={"link"}
                                        icon={<DeleteOutlined/>}
                                        onClick={deleteThisColumn}
                                    />
                                )}
                            </Row>
                        );
                    }
                }
            ]}
            dataSource={pivotColumns}
            onRowsMoved={(sorted: string[]) => setPivotColumns(sorted)}
            showHeader={false}
            onRowMouseEnter$={setHoveredRowIndex}
            onRowMouseLeave$={() => setHoveredRowIndex(undefined)}
        />
    );
}

interface DataNode {
    title: string;
    key: string;
    value: string;
    path: string[],
    isLeaf?: boolean;
    children?: DataNode[];
    addFiltersTo: (filters: GridFilter[]) => void,
}

const updateTreeData = (list: DataNode[], key: Key, children: DataNode[]): DataNode[] => (
    list.map(node => {
        if (node.key === key) {
            return {
                ...node,
                isLeaf: !children.length,
                children,
            };
        }
        if (node.children) {
            return {
                ...node,
                children: updateTreeData(node.children, key, children),
            };
        }
        return node;
    })
);

type PivotTableTreeProps = {
    filters: ReadonlyResource<RegistryFilters>
    registry: DocumentRegistry,
    pivotColumns: string[]
}

function PivotTableTree({filters: filtersRes, registry, pivotColumns}: PivotTableTreeProps) {
    const abortControllers = useAbortControllers([pivotColumns]);
    const filters = useRegistryFilters(filtersRes);
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
    const [initializing, setInitializing] = useState(false);
    const [treeData, setTreeData] = useState<DataNode[]>([]);

    useEffect(() => {
        setExpandedKeys([]);
        setInitializing(true);
        registry.pivotTable(
            pivotColumns,
            [],
            undefined,
            abortControllers.useSignal()
        ).then(data => {
            if (!data.isCompleted()) return;
            setInitializing(false);
            setTreeData(data.val().map(x => ({
                key: x.value,
                title: x.value || "<empty>",
                value: x.value,
                path: [],
                isLeaf: pivotColumns.length <= 1,
                addFiltersTo: filters => filters.push(new ColumnFilter(pivotColumns[0], x.value, pivotColumns[0])),
            })));
        });
    }, [abortControllers, registry, pivotColumns]);

    const loadData = ({key, children, path, isLeaf, value, addFiltersTo: parentAddFiltersTo}: DataNode) => (
        new Promise<void>((resolve, reject) => {
            if (children || isLeaf) {
                resolve();
                return;
            }
            const childrenPath = [...path, value];
            registry.pivotTable(
                pivotColumns,
                childrenPath,
                undefined,
                abortControllers.useSignal()
            ).then(data => {
                if (!data.isCompleted()) return;
                setTreeData(origin => (
                    updateTreeData(origin, key, data.val().map(x => ({
                        key: `${key}-${x.value}`,
                        title: x.value || "<empty>",
                        value: x.value,
                        path: childrenPath,
                        isLeaf: childrenPath.length + 1 >= pivotColumns.length,
                        addFiltersTo: (filters) => {
                            parentAddFiltersTo(filters);
                            filters.push(
                                new ColumnFilter(
                                    pivotColumns[childrenPath.length],
                                    x.value,
                                    pivotColumns[childrenPath.length]
                                )
                            );
                        },
                    })))
                ));
                resolve();
            }).catch(reject);
        })
    );

    return (
        <ImmortalAutoSizer>
            {({height, width}) => (
                <div style={{height, width}}>
                    <Spin spinning={initializing}>
                        <Tree
                            height={height}
                            loadedKeys={[]}
                            expandedKeys={expandedKeys}
                            onExpand={setExpandedKeys}
                            loadData={loadData}
                            treeData={treeData}
                            onSelect={(_, e) => {
                                if (e.selected) {
                                    const newFilters: GridFilter[] = [];
                                    e.node.addFiltersTo(newFilters);
                                    filters.replace(newFilters);
                                } else {
                                    filters.clear();
                                }
                            }}
                        />
                    </Spin>
                </div>
            )}
        </ImmortalAutoSizer>
    );
}