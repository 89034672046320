import React from "react";
import ImageViewer from "./ImageViewer/ImageViewer";
import axios from "axios";
import {API_URL} from "../constants";
import {authHeader} from "../Utilities";
import {Button, Row} from "antd";
import CacheService from "../services/CacheService";
import {withRouter} from "react-router";
import AllResultsService from "../services/AllResultsService";

export class FinalResultViewerInner extends React.Component {
  state = {
    currentUser: null,
    isEditable: false,
    hierarchiesAllowed: false,
    commentsAllowed: false
  };

  loadUser = () => {
    axios.get(API_URL + "/get_current_user", {headers: authHeader()}).then(res => {
      const allPermissions = [...res.data.permissions, ...res.data.organization.permissions];
      CacheService.getProjectData(this.props.match.params.projectId).then(projectData => {
        if (allPermissions.filter(perm => perm.permission_type === "finalize_results" && perm.target === projectData.workspace_id).length > 0) {
          this.setState({isEditable: true});
        }
          if (allPermissions.filter(perm => perm.permission_type === "hierarchies" && perm.target === projectData.workspace_id).length > 0) {
              this.setState({hierarchiesAllowed: true});
          }

          if (allPermissions.filter(perm => perm.permission_type === "comments" && perm.target === projectData.workspace_id).length > 0) {
              this.setState({commentsAllowed: true});
          }
      });
      this.setState({currentUser: res.data});
    }).catch(err => {
    });
  };

  componentDidMount() {
    this.loadUser();
  }

  loadHierarchyNodeAttributes = (hierarchyId) => {
    return AllResultsService.getHierarchyNodeAttributes(hierarchyId, {
      result_id: this.props.match.params.finalResultId,
      is_final: true
    });
  };

  render() {
    return (
      <div style={{display: "flex", flexDirection: "column", width: "100%"}}>{/* DO NOT CHANGE THIS LINE*/}
        <ImageViewer
            loadHierarchyNodeAttributes={this.loadHierarchyNodeAttributes}
            showThumbnailsDock={true}
            editable={this.state.isEditable}
            hierarchiesAllowed={this.state.hierarchiesAllowed}
            commentsAllowed={this.state.commentsAllowed}
            history={this.props.history}
            projectId={this.props.match.params.projectId}
            location={this.props.location}
            parentProps={this.props}
            key={this.props.match.params.finalResultId}
            loadAnnotation={() => {
              return axios.get(
                  API_URL + `/projects/${this.props.match.params.projectId}/final_results/${this.props.match.params.finalResultId}`,
                  {headers: authHeader()}
              );
            }}

            saveChanges={(annotation) => {
              return axios.put(API_URL + `/projects/${this.props.match.params.projectId}/final_results/${this.props.match.params.finalResultId}`,
                {
                  annotation: annotation
                },
                {headers: authHeader()}
              );
            }}
        />
      </div>
    );
  }
}

const FinalResultViewer = withRouter(({history, ...props}) =>
    <FinalResultViewerInner {...props} history={history} key={props.match.params.finalResultId}/>);
export {FinalResultViewer};
