import { changed$, DepSource } from "../useDep";
import { Subject } from "rxjs";
import { API_V2 } from "../..";

export type ColumnPreset = {
    id: string
    name: string
    columns: string[]
    isGlobal: boolean
}

class RemoteDocumentRegistryPresets {
    private readonly registryId: string;

    constructor(registryId: string) {
        this.registryId = registryId;
    }

    async getList(): Promise<ColumnPreset[]> {
        const data = (await API_V2.get(`/documents_registry/${this.registryId}/presets`)).data;
        
        return data.map((item: any) => ({
            id:item.id,
            name:item.name,
            columns:item.columns,
            isGlobal:item.is_global
        })) as ColumnPreset[];
    }

    async create(name: string, isGlobal: boolean, columns: string[]): Promise<ColumnPreset> {
        const data = (await API_V2.post(
            `/documents_registry/${this.registryId}/presets`,
            {name, is_global:!!isGlobal, columns}
        )).data;
        
        return {
            id:data.id,
            name:data.name,
            columns:data.columns,
            isGlobal:data.is_global
        } as ColumnPreset;
    }

    async delete(presetId: string) {
        const response = await API_V2.delete(
            `/documents_registry/${this.registryId}/presets/${presetId}`
        );
        return response;
    }
}

export class DocumentRegistryPresets implements DepSource {
    public readonly [changed$]: Subject<void> = new Subject<void>();

    private readonly remotePresets: RemoteDocumentRegistryPresets;
    private list: ColumnPreset[] = [];

    constructor(registryId: string) {
        this.remotePresets = new RemoteDocumentRegistryPresets(registryId);
    }

    async reload(): Promise<void> {
        await Promise.all([
            this.remotePresets.getList().then(presets => {
                this.setList(presets);
                this[changed$].next();
            })
        ]);
    }

    async create(name: string, isGlobal: boolean, columns: string[]): Promise<ColumnPreset> {
        const preset = await this.remotePresets.create(name, isGlobal, columns);
        this.setList([...this.list, preset]);
        return preset;
    }

    async delete(id: string): Promise<void> {
        await this.remotePresets.delete(id);
        this.setList(this.list.filter(x => x.id !== id));
    }

    asList(): readonly ColumnPreset[] {
        return this.list;
    }

    private setList(presets: ColumnPreset[]) {
        this.list = presets;
        this[changed$].next();
    }
}