import "@glideapps/glide-data-grid/dist/index.css";
import {
    CellArray,
    CompactSelection,
    DataEditor,
    DataEditorProps,
    DataEditorRef,
    EditableGridCell,
    GridCell,
    GridCellKind,
    GridColumn,
    GridSelection,
    Item,
    Rectangle
} from "@glideapps/glide-data-grid";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {PluginBase} from "../../../../services/Plugins";
import {DocumentRegistryViewRef} from "../../DocumentRegistryView";
import range from "lodash/range.js";
import chunk from "lodash/chunk.js";
import {CurrentRegistry, useCurrentRegistry} from "../../Resources/CurrentRegistry";
import {Button, message, notification, Row, Space, Spin} from "antd";
import {
    DocumentRegistry,
    DocumentRegistryColumns,
    DocumentRegistryItem,
    DocumentRegistrySets,
    useRegistryColumns,
    useRegistrySets
} from "../../../../services/DocumentRegistry/DocumentRegistry";
import {DocumentRegistryItemInspectors} from "../../Resources/DocumentRegistryItemInspectors";
import {ImmortalAutoSizer} from "../../../Misc/ImmortalAutoSizer";
import {Filters, FiltersTagList} from "../Filters";
import {
    ColumnFilter,
    FieldSort,
    FileNameFilter,
    FileNameSort,
    GridFilter,
    JustCreatedFilter,
    PageNumberFilter,
    PageNumberSort,
    RegistryFilters,
    SetFilter,
    SetSort,
    useRegistryFilters
} from "../../Resources/RegistryFilters";
import {ReadonlyResource} from "../../../../services/Resources";
import {Dep} from "../../../../services/useDep";
import {useStatefulColumns} from "../../../DataEditorGridExtensions/StatefulColumns/useStatefulColumns";
import {
    ColumnContextItemBoolFilter,
    ColumnContextItemDelete,
    ColumnContextItemDivider,
    ColumnContextItemSortAsc,
    ColumnContextItemSortDesc,
    ColumnContextItemFilter,
    useHeaderMenu
} from "../../../DataEditorGridExtensions/HeaderMenu/useHeaderMenu";
import {ItemType} from "antd/lib/menu/hooks/useItems";
import {
    CellContextItemDeleteRow,
    CellContextItemFilterByCell,
    useCellContextMenu
} from "../../../DataEditorGridExtensions/CellContextMenu/useCellContextMenu";
import {useSubscription} from "../../../../services/useSubscription";
import {NewColumn} from "./Components/NewColumn";
import {NewRow} from "./Components/NewRow";
import {useSafeSpin} from "../../../../services/useSafeSpin";
import {NewSet} from "./Components/NewSet";
import {RegistryColumnIdField, RegistryColumnIdFileName, RegistryColumnIdPageNumber, RegistryColumnIdSet} from "./RegistryColumnIds";

export class PluginDocDataGrid extends PluginBase<DocumentRegistryViewRef> {
    constructor() {
        super("doc-data-grid");
    }

    async build(target: DocumentRegistryViewRef): Promise<void> {
        const inspector = target.res(DocumentRegistryItemInspectors);
        const registry = target.res(CurrentRegistry);
        target.addDock("main", {
            children: (
                <EditorContainer
                    filters={target.res(RegistryFilters)}
                    registry={registry}
                    onInspect={id => {
                        inspector.get().inspect(registry.get().value().getById(id));
                    }}
                />
            ),
            name: "Documents",
            key: "doc-data-grid"
        });
    }
}

type EditorContainerProps = {
    registry: ReadonlyResource<CurrentRegistry>,
    onInspect: (id: string) => void,
    filters: ReadonlyResource<RegistryFilters>
}

function EditorContainer({registry: registryRes, onInspect, filters: filtersRes}: EditorContainerProps) {
    const filters = useRegistryFilters(filtersRes);
    const registry = useCurrentRegistry(registryRes);
    const registryColumns = useRegistryColumns(registry);
    const registrySets = useRegistrySets(registry);
    return (
        <Editor
            filters={filters}
            registryColumns={registryColumns}
            registrySets={registrySets}
            onInspect={onInspect}
            registry={registry}
        />
    );
}

type CellContent = {
    contextItems(row: number): ItemType[],
    edit(newVal: EditableGridCell): Promise<void>,
    getContent(): GridCell,
}

type RowData = {
    asFileNameCell(): CellContent,
    asPageNumberCell(): CellContent,
    asFieldCell(fieldName: string): CellContent,
    asSetCell(setId: string): CellContent,
};

class DataGridRegistryItem {
    private readonly remoteItem: DocumentRegistryItem;
    private fields: { key: string, value: string }[] = [];
    private sets: string[];
    private _fileName: string;
    private _pageNumber: number;

    constructor(
        remoteItem: DocumentRegistryItem, fileName: string, pageNumber: number, fields: { key: string, value: string }[], sets: string[]
    ) {
        this.remoteItem = remoteItem;
        this.fields = fields;
        this._fileName = fileName;
        this._pageNumber = pageNumber;
        this.sets = sets;
    }

    fileName() {
        return this._fileName;
    }

    async editFileName(newFileName: string) {
        const prev = this._fileName;
        this._fileName = newFileName;
        try {
            await this.remoteItem.editFileName(prev, newFileName);
        } catch (e) {
            this._fileName = prev;
            throw e;
        }
    }

    pageNumber() {
        return this._pageNumber;
    }

    async editPageNumber(newPageNumber: number) {
        const prev = this._pageNumber;
        this._pageNumber = newPageNumber;
        try {
            await this.remoteItem.editPageNumber(prev, newPageNumber);
        } catch (e) {
            this._pageNumber = prev;
            throw e;
        }
    }
    
    async editField(fieldName: string, newValue: string) {
        const prev = this.fieldValue(fieldName);
        this.fieldValueSet(fieldName, newValue);
        try {
            await this.remoteItem.editField(fieldName, prev, newValue);
        } catch (e) {
            this.fieldValueSet(fieldName, prev);
            throw e;
        }
    }

    async editSetMembership(setId: string, isMember: boolean) {
        if (isMember) {
            await this.addToSet(setId);
        } else {
            await this.removeFromSet(setId);
        }
    }

    async delete() {
        await this.remoteItem.delete();
    }

    fieldValue(fieldName: string): string {
        return this.fields.find(x => x.key === fieldName)?.value ?? "";
    }

    id() {
        return this.remoteItem.id();
    }

    isInSet(setId: string): boolean {
        return this.sets.includes(setId);
    }

    private async addToSet(setId: string) {
        this.sets.push(setId);
        try {
            await this.remoteItem.addToSet(setId);
        } catch (e) {
            this.sets = this.sets.filter(x => x !== setId);
            throw e;
        }
    }

    private async removeFromSet(setId: string) {
        this.sets = this.sets.filter(x => x !== setId);
        try {
            await this.remoteItem.removeFromSet(setId);
        } catch (e) {
            this.sets.push(setId);
        }
    }

    private fieldValueSet(fieldName: string, fieldValue: string) {
        this.fields = [
            ...this.fields.filter(x => x.key !== fieldName),
            {key: fieldName, value: fieldValue}
        ];
    }
}

// columns must be accessed via 'registryColumns' prop
type DocumentRegistryForEditor = Omit<DocumentRegistry, "columns" | "sets">;

interface EditorProps {
    registryColumns: Dep<DocumentRegistryColumns>
    registrySets: Dep<DocumentRegistrySets>,
    onInspect: (id: string) => void,
    filters: Dep<Filters<GridFilter>>,
    registry: DocumentRegistryForEditor,
}

type RegistryGridColumn = GridColumn & {
    readonly id: string,
    headerItems(close: () => void): ItemType[],
    getCellContent(rowData: RowData): CellContent,
}

function Editor({registry, registryColumns, registrySets, onInspect, filters}: EditorProps) {
    const pageSize = 50;

    const [gridSpinning, startSpin] = useSafeSpin();
    const [loadedRows, setRows] = useState(0);
    const [gridSelection, setGridSelection] = React.useState<GridSelection>({
        columns: CompactSelection.empty(),
        rows: CompactSelection.empty()
    });
    const ref = useRef<DataEditorRef | null>(null);
    const dataRef = React.useRef<RowData[]>([]);
    const loadingRef = React.useRef(CompactSelection.empty());

    const resetResults = useCallback(() => {
        dataRef.current = [];
        setRows(0);
        loadingRef.current = CompactSelection.empty();
    }, []);

    useEffect(() => {
        resetResults();
    }, [resetResults, filters]);

    const getRowData = useCallback(async (r: Item) => {
        const rowDelete = (row: number, item: DataGridRegistryItem) => {
            const spin = startSpin();
            item.delete().then(() => {
                dataRef.current.splice(row, 1);
                ref.current?.focus();
                setRows(prev => prev - 1);
                loadingRef.current = CompactSelection.empty();
            }).finally(() => {
                spin.stop();
            });
        };

        const skip = r[0];
        const limit = r[1] - r[0];
        const query = filters.asList().reduce(
            (q, x) => x.build(q),
            registry.query()
        );
        const items = await query.get(skip, limit);
        if (items.length > 0) {
            setRows(prev => Math.max(skip + items.length, prev));
        }
        return items
            .map(x => new DataGridRegistryItem(x.asItem(), x.fileName, x.pageNumber, x.fields, x.sets))
            .map(x => new class implements RowData {
                asFieldCell(fieldName: string) {
                    const fieldValue = x.fieldValue(fieldName);
                    return {
                        async edit(newVal: EditableGridCell): Promise<void> {
                            if (newVal.kind !== GridCellKind.Text) {
                                throw new Error("Attempt to edit cell of different kind");
                            }
                            await x.editField(fieldName, newVal.data);
                        },
                        getContent(): GridCell {
                            return {
                                kind: GridCellKind.Text,
                                data: fieldValue,
                                allowOverlay: true,
                                displayData: fieldValue,
                                allowWrapping: true
                            };
                        },
                        contextItems(row: number): ItemType[] {
                            return [
                                CellContextItemFilterByCell({
                                    onFilter() {
                                        addFilter(new ColumnFilter(fieldName, fieldValue, fieldName));
                                    }
                                }),
                                CellContextItemDeleteRow({
                                    onDelete() {
                                        rowDelete(row, x);
                                    }
                                })
                            ];
                        }
                    };
                }

                asFileNameCell() {
                    return {
                        async edit(newVal: EditableGridCell): Promise<void> {
                            if (newVal.kind !== GridCellKind.Uri) {
                                throw new Error("Attempt to edit cell of different kind");
                            }
                            await x.editFileName(newVal.data);
                        },
                        getContent(): GridCell {
                            return {
                                kind: GridCellKind.Uri,
                                data: x.fileName(),
                                allowOverlay: true,
                                displayData: x.fileName(),
                                hoverEffect: true,
                                onClickUri: e => {
                                    e.preventDefault();
                                    onInspect(x.id());
                                }
                            };
                        },
                        contextItems(row: number): ItemType[] {
                            return [
                                CellContextItemFilterByCell({
                                    onFilter() {
                                        addFilter(new FileNameFilter(x.fileName(), "File Name"));
                                    }
                                }),
                                CellContextItemDeleteRow({
                                    onDelete() {
                                        rowDelete(row, x);
                                    }
                                })
                            ];
                        }
                    };
                }

                asPageNumberCell() {
                    return {
                        async edit(newVal: EditableGridCell): Promise<void> {
                            if (newVal.kind !== GridCellKind.Number) {
                                throw new Error("Attempt to edit cell of different kind");
                            }
                            await x.editPageNumber(newVal.data || 0);
                        },
                        getContent(): GridCell {
                            return {
                                kind: GridCellKind.Number,
                                data: x.pageNumber(),
                                allowOverlay: true,
                                displayData: x.pageNumber().toString(),
                                thousandSeparator: " "
                            };
                        },
                        contextItems(row: number): ItemType[] {
                            return [
                                CellContextItemFilterByCell({
                                    onFilter() {
                                        addFilter(new PageNumberFilter(x.pageNumber().toString(), "Page Number"));
                                    }
                                }),
                                CellContextItemDeleteRow({
                                    onDelete() {
                                        rowDelete(row, x);
                                    }
                                })
                            ];
                        }
                    };
                }

                asSetCell(setId: string) {
                    return {
                        async edit(newVal: EditableGridCell): Promise<void> {
                            if (newVal.kind !== GridCellKind.Boolean) {
                                throw new Error("Attempt to edit cell of different kind");
                            }
                            await x.editSetMembership(setId, Boolean(newVal.data));
                        },
                        getContent(): GridCell {
                            return {
                                kind: GridCellKind.Boolean,
                                data: x.isInSet(setId),
                                allowOverlay: false,
                            };
                        },
                        contextItems(row: number): ItemType[] {
                            const isInSet = x.isInSet(setId);
                            const setName = registrySets.getSetNameById(setId);
                            return [
                                CellContextItemFilterByCell({
                                    onFilter() {
                                        addFilter(new SetFilter(setId, isInSet, setName));
                                    }
                                }),
                                CellContextItemDeleteRow({
                                    onDelete() {
                                        rowDelete(row, x);
                                    }
                                })
                            ];
                        }
                    };
                }
            }());
    }, [onInspect, filters, registry, startSpin]);

    const addFilter = useCallback((newFilter: GridFilter) => {
        filters.add(newFilter);
    }, [filters]);

    const [columns, statefulColumnsEditorProps, columnsRef] = useStatefulColumns(useCallback<() => RegistryGridColumn[]>(() => {
        return [
            {
                id: RegistryColumnIdFileName(),
                title: "File Name",
                hasMenu: true,
                getCellContent(rowData: RowData): CellContent {
                    return rowData.asFileNameCell();
                },
                headerItems(): ItemType[] {
                    const onFilter = (value: string) => {
                        addFilter(new FileNameFilter(value, this.title));
                    };
                    const onSort = (order: 1 | -1) => {
                        addFilter(new FileNameSort(order, this.title, this.id));
                    };
                    return [
                        ColumnContextItemFilter({onFilter}),
                        ColumnContextItemSortAsc({onSort}),
                        ColumnContextItemSortDesc({onSort})
                    ];
                }
            },
            {
                id: RegistryColumnIdPageNumber(),
                title: "Page Number",
                hasMenu: true,
                getCellContent(rowData: RowData): CellContent {
                    return rowData.asPageNumberCell();
                },
                headerItems(): ItemType[] {
                    const onFilter = (value: string) => {
                        addFilter(new PageNumberFilter(value, this.title));
                    };
                    const onSort = (order: 1 | -1) => {
                        addFilter(new PageNumberSort(order, this.title, this.id));
                    };
                    return [
                        ColumnContextItemFilter({onFilter}, "number"),
                        ColumnContextItemSortAsc({onSort}),
                        ColumnContextItemSortDesc({onSort})
                    ];
                }
            },
            ...registryColumns.asList().map(x => ({
                id: RegistryColumnIdField(x),
                title: x,
                hasMenu: true,
                getCellContent(rowData: RowData): CellContent {
                    return rowData.asFieldCell(x);
                },
                headerItems(close: () => void): ItemType[] {
                    const onFilter = (value: string) => {
                        addFilter(new ColumnFilter(this.title, value, this.title));
                    };
                    const onSort = (order: 1 | -1) => {
                        addFilter(new FieldSort(this.title, order, this.title, this.id));
                    };
                    const onDelete = () => {
                        registryColumns.delete(x)
                            .then(() => {
                                close();
                            })
                            .catch(() => {
                                message.error("Failed to delete the column!");
                            });
                    };
                    return [
                        ColumnContextItemFilter({onFilter}),
                        ColumnContextItemSortAsc({onSort}),
                        ColumnContextItemSortDesc({onSort}),
                        ColumnContextItemDivider(),
                        ColumnContextItemDelete({onDelete})
                    ];
                }
            })),
            ...registrySets.asList().map(x => ({
                id: RegistryColumnIdSet(x),
                title: x.name(),
                hasMenu: true,
                getCellContent(rowData: RowData): CellContent {
                    return rowData.asSetCell(x.id());
                },
                headerItems(close: () => void): ItemType[] {
                    const onFilter = (value: boolean) => {
                        addFilter(new SetFilter(x.id(), value, x.name()));
                    };
                    const onSort = (order: 1 | -1) => {
                        addFilter(new SetSort(x.id(), order, x.name(), this.id));
                    };
                    const onDelete = () => {
                        registrySets.delete(x.id())
                            .then(() => {
                                close();
                            })
                            .catch(() => {
                                message.error("Failed to delete the set!");
                            });
                    };
                    return [
                        ColumnContextItemBoolFilter("Is Member", {onFilter}),
                        ColumnContextItemSortAsc({onSort}),
                        ColumnContextItemSortDesc({onSort}),
                        ColumnContextItemDivider(),
                        ColumnContextItemDelete({onDelete})
                    ];
                }
            }))
        ];
    }, [registrySets, registryColumns, addFilter]));

    useSubscription(() => registrySets.setAdded$.subscribe(set => {
        notification.success({
            message: (
                <>
                    <span>Set was added successfully!</span>
                    <Button
                        type={"link"}
                        onClick={() => {
                            const col = columnsRef.current.findIndex(
                                x => x.id === JSON.stringify({type: "set", key: set.id()})
                            );
                            if (col !== -1) {
                                ref.current?.scrollTo(col, 0);
                            }
                        }}
                    >
                        focus
                    </Button>
                </>
            )
        });
    }), [registryColumns]);

    useSubscription(() => registryColumns.columnAdded$.subscribe(name => {
        notification.success({
            message: (
                <>
                    <span>Column was added successfully!</span>
                    <Button
                        type={"link"}
                        onClick={() => {
                            const col = columnsRef.current.findIndex(
                                x => x.title === name
                            );
                            if (col !== -1) {
                                ref.current?.scrollTo(col, 0);
                            }
                        }}
                    >
                        focus
                    </Button>
                </>
            )
        });
    }), [registryColumns]);

    useSubscription(() => registry.itemAdded$.subscribe(item => {
        notification.success({
            message: (
                <>
                    <span>Row was added successfully!</span>
                    <Button
                        type={"link"}
                        onClick={() => {
                            filters.add(new JustCreatedFilter(item.id()));
                        }}
                    >
                        focus
                    </Button>
                </>
            )
        });
    }), [registry]);

    const args = useAsyncDataSource<RowData>(
        pageSize,
        1,
        getRowData,
        React.useCallback(
            (rowData, col) => {
                return columns[col].getCellContent(rowData).getContent();
            },
            [columns]
        ),
        React.useCallback((cell, newVal, rowData) => {
            const [col] = cell;
            columns[col].getCellContent(rowData).edit(newVal).catch(() => {
                message.error("Failed to update");
            });
            return rowData;
        }, [columns]),
        ref,
        dataRef,
        loadingRef,
    );

    const {cellContextMenu, onCellContextMenu} = useCellContextMenu({
        itemsOf: cell => {
            const [col, row] = cell;
            const rowData = dataRef.current[row];
            if (rowData == null) {
                return [];
            }
            return columns[col]?.getCellContent(rowData)?.contextItems(row) ?? [];
        }
    });
    const {headerMenu, onHeaderMenuClick} = useHeaderMenu({
        itemsOf: (col, close) => columns[col]?.headerItems(close) ?? []
    });

    return (
        <>
            {/*!!!! menus should be rendered before DataEditor*/}
            {cellContextMenu}
            {headerMenu}
            {/*--*/}

            <div style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%"
            }}>
                <div>
                    <Row style={{marginBottom: 8}} justify={"space-between"}>
                        <div>
                            <FiltersTagList filters={filters}/>
                        </div>
                        <Space>
                            <NewColumn registryColumns={registryColumns}/>
                            <NewRow registry={registry}/>
                            <NewSet registrySets={registrySets}/>
                        </Space>
                    </Row>
                </div>
                <div style={{width: "100%", height: "100%"}}>
                    <ImmortalAutoSizer>
                        {({height, width}) => (
                            <div style={{height, width}}>
                                <Spin spinning={gridSpinning}>
                                    <DataEditor
                                        ref={ref}
                                        {...args}
                                        {...statefulColumnsEditorProps}
                                        drawHeader={(args, draw) => {
                                            draw();
                                            const {ctx, menuBounds, column} = args;
                                            if (column.id == null) return;
                                            for (let filter of filters.asList()) {
                                                const order = filter.getColumnSortOrder(column.id);
                                                if (order !== 0) {
                                                    const imgMap = {
                                                        [-1]: process.env.PUBLIC_URL + "/images/sort_desc.svg",
                                                        1: process.env.PUBLIC_URL + "/images/sort_asc.svg",
                                                    };
                                                    const img = new Image();
                                                    img.src = imgMap[order];
                                                    ctx.drawImage(
                                                        img,
                                                        menuBounds.x - 8,
                                                        menuBounds.y + 8,
                                                        16,
                                                        16
                                                    );
                                                    break;
                                                }
                                            }
                                        }}
                                        gridSelection={gridSelection}
                                        onGridSelectionChange={setGridSelection}
                                        width={width}
                                        height={height}
                                        columns={columns}
                                        onHeaderMenuClick={onHeaderMenuClick}
                                        onCellContextMenu={onCellContextMenu}
                                        rows={loadedRows}
                                        rowMarkers="both"
                                        rowSelect={"multi"}
                                    />
                                </Spin>
                            </div>
                        )}
                    </ImmortalAutoSizer>
                </div>
            </div>
        </>
    );
}

// https://github.com/glideapps/glide-data-grid/blob/e79e8983cc2e297206ee5185441ca4edd1dcba47/packages/core/src/docs/examples/server-side-data.stories.tsx
// https://github.com/glideapps/glide-data-grid/blob/main/packages/source/src/use-async-data-source.ts
type RowCallback<T> = (range: Item) => Promise<readonly T[]>;
type RowToCell<T> = (row: T, col: number) => GridCell;
type RowEditedCallback<T> = (cell: Item, newVal: EditableGridCell, rowData: T) => T | undefined;

export function useAsyncDataSource<TRowType>(
    pageSize: number,
    maxConcurrency: number,
    getRowData: RowCallback<TRowType>,
    toCell: RowToCell<TRowType>,
    onEdited: RowEditedCallback<TRowType>,
    gridRef: React.MutableRefObject<DataEditorRef | null>,
    dataRef: React.MutableRefObject<TRowType[]>,
    loadingRef: React.MutableRefObject<CompactSelection>
): Pick<DataEditorProps, "getCellContent" | "onVisibleRegionChanged" | "onCellEdited" | "getCellsForSelection"> {
    pageSize = Math.max(pageSize, 1);
    const [visiblePages, setVisiblePages] = React.useState<Rectangle>({x: 0, y: 0, width: 0, height: 0});
    const visiblePagesRef = React.useRef(visiblePages);
    visiblePagesRef.current = visiblePages;

    const onVisibleRegionChanged: NonNullable<DataEditorProps["onVisibleRegionChanged"]> = React.useCallback(r => {
        setVisiblePages(cv => {
            if (r.x === cv.x && r.y === cv.y && r.width === cv.width && r.height === cv.height) return cv;
            return r;
        });
    }, []);

    const getCellContent = React.useCallback<DataEditorProps["getCellContent"]>(
        cell => {
            const [col, row] = cell;
            const rowData: TRowType | undefined = dataRef.current[row];
            if (rowData !== undefined) {
                return toCell(rowData, col);
            }
            return {
                kind: GridCellKind.Loading,
                allowOverlay: false,
            };
        },
        [toCell]
    );

    const loadPage = React.useCallback(
        async (page: number) => {
            loadingRef.current = loadingRef.current.add(page);
            const startIndex = page * pageSize;
            const d = await getRowData([startIndex, (page + 1) * pageSize]);

            const vr = visiblePagesRef.current;

            const damageList: { cell: [number, number] }[] = [];
            const data = dataRef.current;
            for (let i = 0; i < d.length; i++) {
                data[i + startIndex] = d[i];
                for (let col = vr.x; col <= vr.x + vr.width; col++) {
                    damageList.push({
                        cell: [col, i + startIndex],
                    });
                }
            }
            gridRef.current?.updateCells(damageList);
        },
        [getRowData, gridRef, pageSize]
    );

    const getCellsForSelection = React.useCallback(
        (r: Rectangle): (() => Promise<CellArray>) => {
            return async () => {
                const firstPage = Math.max(0, Math.floor(r.y / pageSize));
                const lastPage = Math.floor((r.y + r.height) / pageSize);

                for (const pageChunk of chunk(
                    range(firstPage, lastPage + 1).filter(i => !loadingRef.current.hasIndex(i)),
                    maxConcurrency
                )) {
                    await Promise.all(pageChunk.map(loadPage));
                }

                const result: GridCell[][] = [];

                for (let y = r.y; y < r.y + r.height; y++) {
                    const row: GridCell[] = [];
                    for (let x = r.x; x < r.x + r.width; x++) {
                        row.push(getCellContent([x, y]));
                    }
                    result.push(row);
                }

                return result;
            };
        },
        [getCellContent, loadPage, maxConcurrency, pageSize]
    );

    React.useEffect(() => {
        const r = visiblePages;
        const firstPage = Math.max(0, Math.floor((r.y - pageSize / 2) / pageSize));
        const lastPage = Math.floor((r.y + r.height + pageSize / 2) / pageSize);
        for (const page of range(firstPage, lastPage + 1)) {
            if (loadingRef.current.hasIndex(page)) continue;
            void loadPage(page);
        }
    }, [loadPage, pageSize, visiblePages]);

    const onCellEdited = React.useCallback(
        (cell: Item, newVal: EditableGridCell) => {
            const [, row] = cell;
            const current = dataRef.current[row];
            if (current === undefined) return;
            onEdited(cell, newVal, current);
        },
        [onEdited]
    );

    return {
        getCellContent,
        onVisibleRegionChanged,
        onCellEdited,
        getCellsForSelection,
    };
}