import React, {useEffect, useRef} from "react";
import {fabric} from "fabric";

export const CanvasContainer = ({onReady}) => {
    const canvasEl = useRef(null);
    const canvasParentEl = useRef(null);
    const canvas = useRef(null);

    useEffect(() => {
        canvas.current = new fabric.Canvas(canvasEl.current);
        onReady(canvas.current);

        const observer = new ResizeObserver(() => {
            if (canvas.current == null) return;
            if (canvasParentEl.current == null) return;
            canvas.current.setWidth(canvasParentEl.current.clientWidth);
            canvas.current.setHeight(canvasParentEl.current.clientHeight);
            canvas.current.renderAll();
        });

        observer.observe(canvasParentEl.current);
    }, []);

    return (
        <>
            <div ref={canvasParentEl} style={{overflow: "visible", height: "100%", width: "100%"}}>
                <canvas id="c" ref={canvasEl}/>
            </div>
        </>
    );
};
